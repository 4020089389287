<template>
    <div style="height: 100%; height: 100%">
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">隐患地图</span></el-breadcrumb-item>
        </el-breadcrumb>
        <div class="map"  id="allmap" v-loading="loading"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading">
            <baidu-map :center="center?center:FirstPos" :zoom="zoom"
                        scroll-wheel-zoom class="baidu_map"
                       @ready="handler" ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I">
                <bm-marker top :position="center" animation="BMAP_ANIMATION_BOUNCE"
                           :icon="{url:require('../assets/img/position.png'),size: {width: 20, height: 25}}"
                           :title="'中心点'">
                </bm-marker>
<!--                一般隐患-->
                <div v-if="NormalList[0]">
                    <bm-marker @click="Norclick(item)" top v-for="(item,index) in NormalList" :key="index" :position="item.address" :title="item.dgeName"
                               :icon="{url:require('../assets/img/normal1.png'),size: {width: 35, height:35}}"
                    >
                        <bm-info-window style="width: 420px" :show="item.showFlag" @close="NorClose(item)">
                            <span class="dge_title">隐患名称</span>
                            <el-tag type="success">{{item.dgeName}}</el-tag>
                            <el-form label-position="left">
                                <el-form-item>
                                     <span slot="label">
                                        <i class="el-icon-data-line"></i>
                                    <span style="color: #99a9bf;"> 隐患等级</span>
                                    </span>
                                    <el-tag class="normal">
                                        一般
                                    </el-tag>
                                </el-form-item>
                                <el-form-item>
                                    <span slot="label">
                                        <i class="el-icon-collection-tag"></i>
                                     隐患分类
                                    </span>
                                    <span style="color: #00a199">{{item.dgeTypeName}}</span>
                                </el-form-item>
                                <el-button @click="showDgeInfo(item.dgeId, item.address.lng,item.address.lat)" style="color: #009199;float: right" type="text" icon="el-icon-info">【详细信息】</el-button>
                            </el-form>

                        </bm-info-window>
                    </bm-marker>
                </div>
<!--                严重隐患-->
                <div v-if="SeriousList[0]">
                    <bm-marker @click="Serclick(item1)" top v-for="(item1,index) in SeriousList" :key="index" :position="item1.address" :title="item1.dgeName"
                               :icon="{url:require('../assets/img/serious1.png'),size: {width: 35, height:35}}"
                               >
                        <bm-info-window style="width: 420px" :show="item1.showFlag" @close="SerClose(item1)">
                            <span class="dge_title">隐患名称</span>
                            <el-tag type="success">{{item1.dgeName}}</el-tag>
                            <el-form label-position="left">
                                <el-form-item>
                                     <span slot="label">
                                        <i class="el-icon-data-line"></i>
                                     隐患等级
                                    </span>
                                    <el-tag class="serious">
                                        严重
                                    </el-tag>
                                </el-form-item>
                                <el-form-item>
                                    <span slot="label">
                                        <i class="el-icon-collection-tag"></i>
                                     隐患分类
                                    </span>
                                    <span style="color: #00a199">{{item1.dgeTypeName}}</span>
                                </el-form-item>
                                <el-button @click="showDgeInfo(item1.dgeId, item1.address.lng,item1.address.lat)" style="color: #009199;float: right" type="text" icon="el-icon-info">【详细信息】</el-button>
                            </el-form>

                        </bm-info-window>
                    </bm-marker>
                </div>
<!--                重要隐患-->
                <div  v-if="ImportantList[0]" >
                    <bm-marker  @click="Impclick(item2)" v-for="(item2,index) in ImportantList" :key="index"  :position="item2.address" :title="item2.dgeName"
                               :icon="{url:require('../assets/img/important1.png'),size: {width: 35, height:35}}">
                        <bm-info-window style="width: 420px" :show="item2.showFlag" @open="ImpOpen(item2)" @close="ImpClose(item2)">
                            <span class="dge_title">隐患名称</span>
                            <el-tag type="success">{{item2.dgeName}}</el-tag>
                            <el-form label-position="left">
                                <el-form-item>
                                     <span slot="label">
                                        <i class="el-icon-data-line"></i>
                                     隐患等级
                                    </span>
                                    <el-tag class="important">
                                        重要
                                    </el-tag>
                                </el-form-item>
                                <el-form-item>
                                    <span slot="label">
                                        <i class="el-icon-collection-tag"></i>
                                     隐患分类
                                    </span>
                                    <span style="color: #00a199">{{item2.dgeTypeName}}</span>
                                </el-form-item>
                                <el-button @click="showDgeInfo(item2.dgeId, item2.address.lng,item2.address.lat)" style="color: #009199;float: right" type="text" icon="el-icon-info">【详细信息】</el-button>
                            </el-form>
                        </bm-info-window>
                    </bm-marker>
                </div>
                <bml-curve-line :points="mapListPoints" strokeColor="#009199" :stroke-opacity="0.7" :stroke-weight="4"></bml-curve-line>
                <bm-geolocation @locationSuccess="location" :locationIcon="{url:require('../assets/img/nowpos.png'),size: {width: 22, height: 26}}" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
            </baidu-map>
        </div>
<!--        展示单个隐患信息-->
        <el-dialog
                width="47.9%"
                :title="this.OneDgeForm.dgeName"
                :visible.sync="ToshowDge"
                @close="DgeDialogClosed">
            <!--      内容主体区域-->
<!--            基本信息-->
            <div class="flex_">
                <div>
                    <img src="../assets/img/baseInfo.svg" class="icon_add" alt="">
                </div>
                <div class="form_til">基本信息</div>
            </div>

<!--            <el-divider></el-divider>-->
            <el-card
                    v-loading="toloading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    style="margin-top: 10px; margin-bottom: 12px">
                <div>
                    <span class="form_smtil">
                        <i class="el-icon-position"></i>
                     地址:
                    </span>
                    <span style="color: #00a199">{{this.address}}</span>
                </div>
                <el-col :span="12">
                    <span class="form_smtil">
                        <i class="el-icon-collection-tag"></i>
                     隐患分类:
                    </span>
                    <span style="color: #00a199">{{this.OneDgeForm.dgeTypeName}}</span>
                </el-col>
                <el-col :span="12">
                     <span class="form_smtil">
                         <i class="el-icon-data-line"></i>
                         隐患等级:
                     </span>
                    <el-tag v-if="this.OneDgeForm.dgeLevel === '一般'" class="normal">
                        {{this.OneDgeForm.dgeLevel}}
                    </el-tag>
                    <el-tag v-else-if="this.OneDgeForm.dgeLevel === '严重'" class="serious">
                        {{this.OneDgeForm.dgeLevel}}
                    </el-tag>
                    <el-tag v-else-if="this.OneDgeForm.dgeLevel === '重要'" class="important">
                        {{this.OneDgeForm.dgeLevel}}
                    </el-tag>
                </el-col>
                <el-col :span="13">
                    <span class="form_smtil">
                        <i class="el-icon-alarm-clock"></i>
                     最新巡视时间:
                    </span>
                    <span style="color: #00a199">{{this.OneDgeForm.updateTime}}</span>
                </el-col>
                <el-col :span="11">
                    <span class="form_smtil">
                        <i class="el-icon-house"></i>
                     所属部门
                    </span>
                    <span style="color: #00a199">{{this.OneDeptForm.deptName}}</span>
                </el-col>
                <br>
                <el-col>
                    <span class="form_smtil">
                        <i class="el-icon-info"></i>
                     隐患描述
                    </span>
                    <span style="color: #969896">{{((this.OneDgeForm.dgeInfo + "").split('imgGroup:')[0]=== 'null') ? '暂无隐患描述' : (this.OneDgeForm.dgeInfo + "").split('imgGroup:')[0] }}</span>
                </el-col>
            </el-card>

<!--            现场照片-->
            <div class="flex_">
                <div>
                    <img src="../assets/img/current.svg" class="icon_add" alt="">
                </div>
                <div class="form_til">现场照片</div>
            </div>
            <el-row>
                <div style="margin: 10px 5%" v-if="imglist[0]==='null'">
<!--                    <el-col :span="4">-->
<!--                        <img src="../assets/img/default.svg" class="Xc_pic" alt="">-->
<!--                    </el-col>-->
                    暂无
                </div>
                <div v-else>
                    <br>
                    <div  v-for="(src,index) in imglist" :key="index" >
<!--                        <el-col class="img_pad" :span="4">-->
<!--                            <el-image  fit="cover" class="XC_pic" :src="src">-->
<!--                                <div slot="error" class="image-slot">-->
<!--                                    <i class="el-icon-picture-outline"></i>-->
<!--                                </div>-->
<!--                            </el-image>-->
<!--                        </el-col>-->
                        <el-col class="img_pad" :span="4">
                            <viewer :images="imglist" class="XC_pic">
                                <img :src="src" style="width:100%;">
                            </viewer>
                        </el-col>
                    </div>
                </div>
            </el-row>


            <br>
<!--           巡检记录 -->
            <div class="flex_">
                <div>
                    <img src="../assets/img/xunjianjilu.svg" class="icon_add" alt="">
                </div>
                <div class="form_til">巡检记录</div>
            </div>
            <div v-if="ChkForm[0]">
                <el-divider></el-divider>
                <el-timeline v-loading="chkloading"
                             element-loading-text="拼命加载中"
                             element-loading-spinner="el-icon-loading">
                    <el-timeline-item v-for="(item,index) in ChkForm" :key="index"
                                      :timestamp="item.updateTime" size="large" placement="top"
                                      color="#009199">
                        <el-card>
                            <h4 style="color: #009199">巡视情况</h4>
                            <p style="color: #969896">{{((item.chkInfo + "").split('imgGroup:')[0] === 'null') ? '暂无巡视信息' : ((item.chkInfo + "").split('imgGroup:')[0])}}</p>
                            <!--                        <el-button @click="showDetail(item.deptId, item.empId, item.updateTime,item.createTime)" style="color: #009199;float: right" type="text" icon="el-icon-info">【详细信息】</el-button>-->
                            <div v-if="showDetailInfo">
                                <el-collapse  v-model="activeNames" accordion @change="collapse(item.deptId, item.empId, item.updateTime,item.createTime)">
                                    <el-collapse-item :name="index">
                                        <el-col>
                                        <span class="form_smtil">
                                            <i class="el-icon-user-solid"></i>
                                         巡视人:
                                        </span>
                                            <span style="color: #009199">{{chkPerson}}</span>
                                            <span style="color: #009199">
                                            {{item.chkMin}}
                                            <span style="color: #969896"> 分钟 </span>
                                        </span>
                                            <!--                                        <span v-if="dateTime[0]" style="margin-left: 2%;color: #009199">【</span>-->
                                            <!--                                        <span v-if="dateTime.dayDiff" style="color: #009199">-->
                                            <!--                                            {{dateTime.dayDiff}}<span style="color: #969896"> 天 </span>-->
                                            <!--                                        </span>-->
                                            <!--                                        <span v-if="dateTime.hours" style="color: #009199">-->
                                            <!--                                            {{dateTime.hours}}<span style="color: #969896"> 时 </span>-->
                                            <!--                                        </span>-->
                                            <!--                                        <span v-if="dateTime.minutes" style="color: #009199">-->
                                            <!--                                            {{dateTime.minutes}}<span style="color: #969896"> 分 </span>-->
                                            <!--                                        </span>-->
                                            <!--                                        <span v-if="dateTime.second" style="color: #009199">-->
                                            <!--                                            {{dateTime.second}}<span style="color: #969896"> 秒</span>-->
                                            <!--                                        </span>-->
                                            <!--                                        <span v-if="dateTime[0]" style="color: #009199">】</span>-->
                                        </el-col>
                                        <el-col>
                                        <span class="form_smtil">
                                            <i class="el-icon-house"></i>
                                         所属部门:
                                        </span>
                                            <span style="color: #969896">{{deptName}}</span>
                                        </el-col>
                                        <el-row>
                                         <span slot="label">
                                             <i class="el-icon-picture"></i>
                                             现场照片
                                        </span>
                                            <div v-if="chkimg[index][0] === 'null'">
                                                <!--                                            <el-col :span="4">-->
                                                <!--                                                <img src="../assets/img/default.svg" class="Xc_pic" alt="">-->
                                                <!--                                            </el-col>-->
                                                暂无
                                            </div>
                                            <div v-else>
                                                <br>
                                                <div v-for="(item,index1) in chkimg[index]" :key="index1">
                                                    <el-col class="img_pad" :span="4">
                                                        <!--                                                    <el-image fit="cover" class="XC_pic" :src="item1">-->
                                                        <!--                                                        <div slot="error" class="image-slot">-->
                                                        <!--                                                            <i class="el-icon-picture-outline"></i>-->
                                                        <!--                                                        </div>-->
                                                        <!--                                                    </el-image>-->
                                                        <viewer :images="chkimg[index]" class="XC_pic">
                                                            <img :src="item" style="width:100%;">
                                                        </viewer>
                                                    </el-col>
                                                </div>

                                            </div>
                                        </el-row>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div v-else-if="! ChkForm[0]" style="margin: 10px 4.8%;color: #969896">暂无巡检记录</div>
        </el-dialog>
    </div>
</template>

<script>
import {BaiduMap, BmGeolocation, BmMarker, BmInfoWindow, BmlCurveLine} from "vue-baidu-map/components";
// import normal from  '../assets/img/normal.png';
// import serious from '../assets/img/serious.png';
// import important from '../assets/img/important.png';
export default {
    components: {
        BaiduMap, BmGeolocation, BmMarker, BmInfoWindow, BmlCurveLine
    },
    name: "Maps",
    data(){
        return{
            loading:true,
            // 中心点
            center: '',
            map:'',
            zoom: 10,
            // 目前位置
            Pos:{lng: '', lat: ''},
            // 初始位置
            FirstPos: {lng:121.41003, lat:29.65537} ,
        //    所有隐患
            AllDgelist: [],
            // 一般隐患
            NormalList:[],
            // 严重隐患
            SeriousList: [],
            // 重要隐患
            ImportantList: [],
            mapListPoints: [],
            ToshowDge: false,
            OneDgeForm: [],
            imglist: [], // 隐患照片
            chkimg: [],
            address: '', // 地址
            OneDeptForm: [], // 查找某个部门
            ChkForm: [], // 查找某个隐患的巡视记录
        //    巡视人
            chkPerson: '',
        //    供电所
            deptName: '',
            showDetailInfo: true,
            dateTime: [],
            activeNames: ['0'],
            toloading: true, // 加载隐患情况
            chkloading: true, // 加载巡视情况
            baseUrl: "//ele.qiniu.heilama.com/",
        }
    },
    created() {
        this.getDgeList()
    },
    methods:{
        collapse(deptId,empId, endTime,startTime){
            // this.showDetailInfo = false
            this.getOneDept(deptId)
            this.getUserInfo(empId)
            this.showDetailInfo = true
            this.calculationDate(startTime,endTime)
        },
        handler ({BMap, map}) {
            // console.log(BMap, map)
            // var point = new BMap.Point(this.center.lng,this.center.lat);
            // map.centerAndZoom(point, this.zoom);
            // var myIcon = new BMap.Icon(posIcon, new BMap.Size(20,25));
            // var marker = new BMap.Marker(point, {icon: myIcon}, {animation:"BMAP_ANIMATION_BOUNCE"}); // 创建标注
            // map.addOverlay(marker)
            var view = map.getViewport(eval(this.mapListPoints))
            var mapSize = view.zoom
            this.center = view.center
            this.zoom = mapSize
            this.map = BMap


            // console.log(view)
        },
        // 获取所有隐患   // 腾讯地图经纬度转百度地图经纬度
        async getDgeList () {
            var role = localStorage.getItem('role')
            // console.log(role)
            if (role === 'admin'){
                const { data: res } = await this.$http.get('/danger/findAll')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.AllDgelist = res.data
            }
           else {
                const { data: res } = await this.$http.get('/danger/findByDept/' + localStorage.getItem('deptId'))
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.AllDgelist = res.data
            }
            var Normal = []
            var Serious = []
            var Important = []
            var Point = []
            this.AllDgelist.forEach(function (item) {
                // var x_pi = 3.14159265358979324;
                // var x = parseFloat(item.dgeLng);
                // var y = parseFloat(item.dgeLat);
                // var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                // var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                // var lng = (z * Math.cos(theta) + 0.0065);
                // var lat = (z * Math.sin(theta) + 0.006);
                var lat = item.dgeLat
                var lng = item.dgeLng
                // console.log(lat,lng)
                if (item.dgeLevel === '一般') {
                    Normal.push({address:{lng:lng, lat:lat},dgeName:item.dgeName,dgeTypeName: item.dgeTypeName,dgeId: item.dgeId, showFlag:false})
                }
                else if (item.dgeLevel === '严重') {
                    Serious.push({address:{lng:lng, lat:lat},dgeName:item.dgeName,dgeTypeName: item.dgeTypeName,dgeId: item.dgeId, showFlag:false})
                }
                else if (item.dgeLevel === '重要'){
                    Important.push({address:{lng:lng, lat:lat},dgeName:item.dgeName,dgeTypeName: item.dgeTypeName,dgeId: item.dgeId, showFlag:false})
                }
                Point.push({lng:lng, lat:lat})
            })
            this.NormalList = Normal
            this.SeriousList = Serious
            this.ImportantList = Important
            this.mapListPoints = Point
            this.loading = false
            // console.log(Normal)
        },
        location(point){
            // console.log(point)
            this.Pos.lng = point.currentTarget.ND.lng
            this.Pos.lat = point.currentTarget.ND.lat
            // console.log(this.center)
        },
        NorClose (item) {
            item.showFlag = false
        },
        Norclick(item){
            item.showFlag = true
        },
        SerClose (item1) {
           item1.showFlag = false
        },
        Serclick(item1){
            item1.showFlag = true
        },
        ImpOpen(item2){
          item2.showFlag = true
        },
        ImpClose (item2) {
            item2.showFlag = false
        },
        Impclick(item2){
            item2.showFlag = true
            // console.log(this.Impshow)
        },
        // 展现单个隐患
        showDgeInfo(id,lng,lat){
            // console.log(lng)
            this.ToshowDge = true
            this.getOneInfo(id)
            // console.log(lat)
            this.getAddressName(lng,lat)
        },
        DgeDialogClosed(){
          this.ToshowDge = false
          this.showDetailInfo = true
        },
        // 根据坐标逆解析地址    // 腾讯经纬度转换到百度经纬度
        getAddressName(lng,lat){
            let that = this
            // var map = new window.BMap.Map("allmap");
            // var point = new window.BMap.Point(lng,lat);
            // map.centerAndZoom(point,18);
            // map.enableScrollWheelZoom(true)
            // map.enableDoubleClickZoom(true)
            // 创建解析器
            var myGeo = new window.BMap.Geocoder();
            var pt = new window.BMap.Point(lng,lat);// 随便写个经纬度
            myGeo.getLocation(pt,function(result){
                // console.log(result);
                that.address = result.address
            });

        },
        // 查看某一隐患信息
        async getOneInfo(id){
            const { data: res } = await this.$http.get('/danger/findById/'+id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.OneDgeForm = res.data
            var imgstr = (res.data.dgeInfo+ "").split('imgGroup:')[1]
            if(imgstr) {
                var that = this
                var arr = (imgstr+ "").split(",")
                var list = []
                arr.forEach(function (item) {
                    list.push(that.baseUrl + item)
                })
                that.imglist = list
            }
            else {
                this.imglist = ["null"]
            }
            this.getChkInfo(id)
            this.getOneDept(res.data.deptId)
            this.toloading = false
        },
    //    查看某一部分信息
        async getOneDept(id){
            const { data: res } = await this.$http.get('/dept/showInfo/'+id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.OneDeptForm = res.data
            this.deptName = res.data.deptName
        },
    //    查看某个隐患的巡视记录
        async getChkInfo(id){
            const { data: res } = await this.$http.get('/check/findByDanger/'+id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.ChkForm = res.data
            for(var i =0 ;i<res.data.length;i++){
                var imgstr = (res.data[i].chkInfo+ "").split('imgGroup:')[1]
                if(imgstr) {
                    let that = this
                    var arr = (imgstr+ "").split(",")
                    var list = []
                    arr.forEach(function (item) {
                        list.push(that.baseUrl + item)
                    })
                    that.chkimg[i] = list
                }
                else {
                    this.chkimg[i] = ["null"]
                }
            }
            this.chkloading = false
        },
        async getUserInfo(id){
            const { data: res } = await this.$http.get('/emp/showInfo/'+id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.chkPerson = res.data.empName
        },
        showDetail(deptId,empId, endTime, startTime){
            this.getOneDept(deptId)
            this.getUserInfo(empId)
            this.showDetailInfo = true
            this.calculationDate(startTime,endTime)
        },
        calculationDate(startTime,endTime) {
            startTime = startTime.substring(0,19)
            startTime = startTime.replace(/-/g,'/')
            endTime = endTime.substring(0,19)
            endTime = endTime.replace(/-/g,'/')
            var dateBegin = new Date(startTime)
            var dateEnd = new Date(endTime)
            var dateDiff = dateEnd.getTime() - dateBegin.getTime()        //时间差的毫秒数
            var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000))    //计算出相差天数
            var leave1=dateDiff%(24*3600*1000)                            //计算天数后剩余的毫秒数
            var hours=Math.floor(leave1/(3600*1000))                  //计算出小时数
            //计算相差分钟数
            var leave2=leave1%(3600*1000)                                //计算小时数后剩余的毫秒数
            var minutes=Math.floor(leave2/(60*1000))                  //计算相差分钟数
            //计算相差秒数
            var leave3=leave2%(60*1000);                                  //计算分钟数后剩余的毫秒数
            var seconds=Math.round(leave3/1000)
            var dateTime = []
            dateTime.dayDiff = dayDiff
            dateTime.hours = hours
            dateTime.minutes = minutes
            dateTime.seconds = seconds
            this.dateTime = dateTime
            // console.log(this.dateTime)
            // console.log(startTime)
            // console.log(endTime)
        }
    },
}
</script>

<style scoped>
.map{
    width: 100%;
    height: 95%;
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*z-index: -999;*/
}
    .baidu_map{
        height: 100%;
        width: 100%;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    /deep/.el-form-item__label {
        width: 220px;
        color: #99a9bf;
        margin-left: 40px;
    }
   /deep/ .BMap_pop {
        background: #eeeeee !important;
        border: 2px solid #E4E7ED;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06);
    }
    /deep/.BMap_top {
      /*background: #009199 !important;*/
    }
    .dge_title{
        color: #009199;
        font-weight: bold;
        font-size: 28px;
        padding-right: 5%;
    }
/*标签一般*/
.normal{
    background: #FF9900;
    color: white;
    border-radius: 18px;
    border: 1px solid #FF9990;
}
/*标签重要*/
.important{
    background: #D8262A;
    color: white;
    border-radius: 18px;
    border: 1px solid #D82620;
}
/*标签严重*/
.serious{
    background: #8F2139;
    color: white;
    border-radius: 18px;
    border: 1px solid #8F2100;
}
.icon_add{
    width: 32px;
    height: 32px;
    padding-right: 10px;
    vertical-align:middle;
}
    .form_til{
        color: #009199;
        font-weight: bold;
        font-size: 25px;
        text-align: center;
    }
    .form_smtil{
        color: #404040;
        font-size: 22px;
        font-weight: bold;
        padding-right: 6px;
        line-height: 48px;
        font-size: 22px;
    }
    .XC_pic{
        width: 80px ;
        height: 80px;
    }
    .img_pad{
        margin: 30px 0
    }
    .flex_ {
        display: flex;
        align-items: center;
    }
</style>
