<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <el-header class="head">
        <div class="header2">
          <div class="left">
              <div style="display: flex;align-items: center">
                  <div><img src="../assets/img/logo.png" class="img_logo" alt=""></div>
                  <div>
                      <img src="../assets/img/ligong_title.png" class="img_title" alt="">
                  </div>
              </div>
<!--                <span class="logo_mc">理工管控系统</span>-->
          </div>
          <div class="right"><img src="../assets/img/user.svg" class="icons" alt="">
            <el-dropdown @command="handleCommand">
                <span class="chenghu">
                  {{userrole === 'admin' ? '超级管理员' : (userrole === 'manager' ? '管理员' : '员工')}}
              </span>
              <el-dropdown-menu slot="dropdown">
<!--                <el-dropdown-item command="a">个人中心</el-dropdown-item>-->
                <el-dropdown-item command="a">点击退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
<!--          <el-button type="primary" @click="logout" plain>点击退出</el-button>-->
        </div>
        <div class="header">
        </div>
      </el-header>
      <!--    页面主体-->
      <el-container style="height: 100%">
        <!--      侧边栏-->
        <el-aside style="height: 100%">
          <!--        侧边栏菜单区域-->
          <el-menu class="aside" background-color="#002232" text-color="#ffffff" active-text-color="#ffffff"
                   :router="true" :default-active="activePath" :unique-opened="true"
                   @open="handleOpen"
                   @close="handleClose">
            <!--          菜单-->
            <div style="height: 3.6vh"></div>
<!--            数据统计-->
            <el-menu-item index="Static" @click="saveNavState('Static')">
                <i class="el-icon-s-data svg"></i>
              <span slot="title" class="menu_item">数据统计</span>
            </el-menu-item>
<!--              巡视时长统计-->
              <el-menu-item v-if="this.userrole !== 'employee'" index="chkStatic" @click="saveNavState('chkStatic')">
                  <img src="../assets/img/menu/data.svg" class="svg" alt="">
                  <span slot="title" class="menu_item">巡检统计</span>
              </el-menu-item>
<!--            隐患地图-->
            <el-menu-item index="Maps" @click="saveNavState('Maps')">
                <img src="../assets/img/menu/map.svg" class="svg" alt="">              <span slot="title" class="menu_item">隐患地图</span>
            </el-menu-item>
<!--            隐患录入-->
            <el-menu-item index="dgeInfo" @click="saveNavState('dgeInfo')">
                <img src="../assets/img/menu/input.svg" class="svg" alt="">
              <span slot="title" class="menu_item">隐患录入</span>
            </el-menu-item>
<!--            隐患审核-->
            <el-menu-item index="checkDge" @click="saveNavState('checkDge')">
                <img src="../assets/img/menu/check.svg" class="svg" alt="">
              <span slot="title" class="menu_item">隐患审核</span>
            </el-menu-item>
            <el-menu-item index="AddPart" @click="saveNavState('AddPart')">
             <i class="el-icon-s-cooperation svg"></i>
              <span slot="title" class="menu_item">部门管理</span>
            </el-menu-item>
              <el-menu-item v-if="this.userrole === 'admin'" index="Manage" @click="saveNavState('Manage')">
                  <img src="../assets/img/menu/manage.svg" class="svg" alt="">
                  <span slot="title" class="menu_item">账号管理</span>
              </el-menu-item>
          </el-menu>
        </el-aside>
        <!--      右侧内容主体-->
        <el-main>
          <!--        路由占位符-->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      // 被激活的链接地址
      activePath: '',
      userrole: localStorage.getItem('role'),
      width:document.documentElement.clientWidth,
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath') ? window.sessionStorage.getItem('activePath') : 'Static'
    this.checkToken()
  },
  methods: {
    // 获取部门种类
    // 退出登录
    handleCommand(command) {
      if(command=== 'a'){
        window.sessionStorage.clear()
        localStorage.clear()
        this.$router.push('/login')
      }
    },
    logout () {
      window.sessionStorage.clear()
      localStorage.clear()
      this.$router.push('/login')
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    // 保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = window.sessionStorage.getItem('activePath')
      // console.log(this.activePath)
    },
    // 验证token是否失效
    async checkToken () {
      const { data: res } = await this.$http.get('/user/verify/')
      // console.log(res)
      if (res.status !== 200) {
        window.sessionStorage.clear()
        localStorage.clear()
        this.$router.push('/login')
      }
    }
  },

}
</script>

<style scoped>
    .head{
        position: relative;
        width: 100%;
        height: 7.4vh !important;
        /*background: #009199;*/
    }
    .header{
        background: #009199;
        height: 100%;
        /*width: 100%;*/
        box-sizing: border-box;
        border: 1px solid #009199;
        border-radius: 10px;
        width: 29.6vw;
        transform-origin:100% 0%;
        transform:skewX(-50deg);
        position: absolute;
        top: 0;
        left: -1%;
        z-index: -10;
    }
    .header2{
        position:absolute;
        left: 0%;
        width: 100%;
        height: 6vh;
        background: #009199;
        top: 0;

    }
    .left{
        margin: auto;
        position: absolute;
        z-index: 100;
    }
    .img_logo{
        width: 11.3vw;
        height: 4.9vh;
        vertical-align:middle;
        margin: 1.26vh 0;
        margin-left: 8px;
        position: relative;
    }
    .logo_mc{
        font-family: FZLTCHJW--GB1-0;
        font-size: 30px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        margin: auto;
    }
    .right{
        margin: auto;
        right: 1.563vw;
        top:1.85vh;
        z-index: 102;
        position: absolute;
    }
    .select{
        color: white;
        font-size: 20px;
        padding-right: 12px;
    }
    .chenghu{
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
    }
    .icons{
        width: 2.78vh ;
        height :2.78vh;
        vertical-align:middle;
        margin-right: 1.56vw;
        /*transform: scale(0.9);*/
    }
    .aside{
        background: #002232;
        height: 100%;
        width: 10.4vw;
        cursor: pointer;
        /*opacity: 0.8;*/
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #FFFFFF;
        /*line-height: 22px;*/
    }
    .svg{
        width: 1.05vw;
        height: 1.05vw;
    }
    .menu_item{
        padding-left: 0.5vw;
        width: 10vw;
        height: 6.48vh;
        line-height: 6.48vh;
    }
    @media all and (max-width: 768px) {
        .menu_item{
            font-size: 0px;
        }
        .logo_mc{
            font-size: 0px;
        }
    }
</style>

<style lang="less" scoped>
    //设置了行高和字体大小、高度颜色
    .el-menu-item {
        font-size: 16px;
        width: 10vw;
        height: 6.48vh;
        margin: 0.6vw auto;
        margin-right: 1.04vw;
        /*transition: none !important;*/
    }
    //设置选中el-menu-item时的样式
    .el-menu-item:hover{
        background: #009199 !important;
        /*!*margin-top: 4px;*!*/
        /*color: #eeeeee;*/
        border-radius: 10px;
        margin-right: 1.04vw;
        /*transform: scaleX(-1);*/
        /*transform: translate(-1%, 0%);*/
    }
    .el-menu-item:focus{
        transform: rotateY(-10deg);
    }
    .el-menu-item.is-active{
        background: #009199 !important;
        margin-right: 1.04vw;
        border-radius: 10px;
    }
    .el-aside{
        width: 10.4vw !important;
        overflow: hidden;
    }
    @media all and (max-width: 768px) {
        .el-aside{
            width: 70px !important;
            overflow: hidden;
        }
    }
    .img_title {
        width: 200px;
        height: 35px;
        margin-left: 1.56vw;
    }
</style>
