import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/font/iconfont.css'
import '../src/assets/css/global.css'
import axios from 'axios'
import './plugins/element.js'
import '../src/util/rem'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.prototype.$axios = axios
Vue.prototype.$http = axios

axios.defaults.baseURL='/api'

axios.interceptors.request.use(config => {
  // console.log(config)
  config.headers.Authorization = 'Bearer' + ' ' + localStorage.getItem('token')
  // 必须在最后返回config
  return config
}, error => {
  return Promise.reject(error)
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
