<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">数据统计</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-row>
            <el-col :span="8">
                <el-card shadow="hover" class="header_card">
                    <div class="center">
                        <el-col :span="8">
                            <el-button class="btn_jump" type="text" @click="dgeNowNum('all')">
                                <span style="color: #009DDA;" class="header_num">{{this.dgeData.all}}</span>
                            </el-button>
                            <br>
                            <span>当前隐患总数</span>
                            <br>
                        </el-col>
                        <el-col :span="1">
                            <el-divider direction="vertical"></el-divider>
                            <el-divider direction="vertical"></el-divider>
                        </el-col>
                        <el-col :span="5">
                            <el-button class="btn_jump" type="text" @click="dgeNowNum('serious')">
                                <span style="color: #8F2139;" class="header_num">{{this.dgeData.serious}}</span>
                            </el-button>
                            <br>
                            <span>严重</span>
                        </el-col>
                        <el-col :span="5">
                            <el-button class="btn_jump" type="text" @click="dgeNowNum('important')">
                                <span style="color: #D8262A;" class="header_num">{{this.dgeData.important}}</span>
                            </el-button>
                            <br>
                            <span>重要</span>
                        </el-col>
                        <el-col :span="5">
                            <el-button class="btn_jump" type="text" @click="dgeNowNum('normal')">
                                <span style="color: #FF9900;" class="header_num">{{this.dgeData.normal}}</span>
                            </el-button>
                            <br>
                            <span>一般</span>
                        </el-col>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="always" class="header_card">
                    <div class="center">
                        <el-button class="btn_jump" type="text" @click="dgeNowNum('TodayAdd')">
                            <span style="color: #D8262A;" class="header_num">{{this.dgeData.create}}</span>
                        </el-button>
                        <br>
                        <span>今日上报隐患</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4" >
                <el-card shadow="always" class="header_card">
                    <div class="center">
                        <el-button class="btn_jump" type="text" @click="chkNowNum('chkNum')">
                            <span style="color: #0073FF;" class="header_num">{{this.TodayChk}}</span>
                        </el-button>
                        <br>
                        <span>今日巡检次数</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="always" class="header_card">
                    <div class="center">
                        <el-button class="btn_jump" type="text" @click="dgeNowNum('TodayDel')">
                            <span style="color: #27C37D;" class="header_num">{{this.dgeData.done}}</span>
                        </el-button>
                        <br>
                        <span>今日消除隐患</span>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="always" class="header_card">
                    <div class="center">
                        <el-button class="btn_jump" type="text" @click="dgeNowNum('noCheck')">
                            <span style="color: #FF9900;" class="header_num">{{this.dgeData.no}}</span>
                        </el-button>
                        <br>
                        <span>待审核隐患</span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="15">
                <el-card class="body_num">
                    <div class="main_body">
                        <el-row>
                            <el-col :span="12">
                                <span class="main_title">新增隐患/消除隐患统计</span>
                            </el-col>
                            <el-col :span="9" :offset="3">
                                <el-button @click="drawNewDge(dgeWeek)">周</el-button>
                                <el-button @click="drawNewDge(dgeMon)">月</el-button>
                                <el-button @click="drawNewDge(dgeYear)">年</el-button>
                            </el-col>
                        </el-row>
                    <div class="chart">
                        <div v-loading="loading1"
                             element-loading-text="拼命加载中"
                             element-loading-spinner="el-icon-loading" id="newDge" style="width: 95%;height:100%;"></div>
                    </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="9">
                <el-card class="body_num">
                    <div class="main_body">
                        <el-row>
                            <el-col :span="8">
                                <span class="main_title">隐患上报对比</span>
                            </el-col>
                            <el-col :span="12" :offset="1">
                                <el-button @click="drawDgeComp(dgeComp,'week')">周</el-button>
                                <el-button @click="drawDgeComp(dgeComp,'Mon')">月</el-button>
                            </el-col>
                        </el-row>
                    <div class="chart"  v-loading="loading2"
                         element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading">
                        <div id="myChart" style="width: 95%;height:100%;"></div>
                    </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-card class="body_num">
                <div class="main_body">
                    <el-row>
                        <el-col :span="8">
                            <span class="main_title">隐患上报对比</span>
                        </el-col>
                        <el-col :span="12" :offset="1">
                            <el-button @click="chkTime(chkDay)">日</el-button>
                            <el-button @click="chkTime(chkWeek)">周</el-button>
                            <el-button @click="chkTime(chkMon)">月</el-button>
                        </el-col>
                    </el-row>

                    <div class="chart" v-loading="loading3"
                         element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading">
                        <div id="chkTime" style="margin-top:5px;width: 90%;height:95%;"></div>
                    </div>
                </div>
            </el-card>

        </el-row>
    </div>
</template>

<script>
    import * as echarts from 'echarts/index';
    export default {
        name: "Main",
        data(){
            return{
                flag:false,
                loading1: true, // dge
                loading2: true, // Comp
                loading3: true, // Chk
                dgeData: [], // 隐患数目统计
                TodayChk: '', // 今日巡视次数
                dgeComp: [], // 月周上报隐患总数
                // 上报消除隐患
                dgeWeek: [], // 周
                dgeMon: [],// 月
                dgeYear: [],// 年
                // 巡检时长
                chkDay: [], // 天
                chkWeek: [],
                chkMon: [],
                Dgechart: '',
                ComChart: '',
                ChkChart: ''
            }
        },
        mounted() {
            this.init()
        },
        created() {
            this.getTodayChk() // 今日巡视
            this.getdgeData() // 隐患数量
            this.getDgeComp() // 上报隐患对比
            // 上报消除隐患
            this.getdgeWeek() // 周
            this.getdgeMon() // 月
            this.getdgeYear() // 年
            // 巡检时长
            this.getchkDay()
            this.getchkWeek()
            this.getchkMon()
        },
        methods: {
            // 点击数字进行跳转
            dgeNowNum (type) {
                this.$router.push({path: '/dgeInfos', query: {type: type}})
            },
            chkNowNum (type) {
                this.$router.push({path: '/chkInfos', query: {type: type}})
            },
            async getTodayChk() {
                const {data: res} = await this.$http.get('/check/chkData')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.TodayChk = res.data.today
            },
            async getdgeData() {
                const {data: res} = await this.$http.get('/danger/dgeData')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeData = res.data
            },
            // 隐患上报对比
            async getDgeComp(){
                const {data: res} = await this.$http.get('/danger/dgeComp')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeComp = res.data
                this.drawDgeComp(res.data,'week')
                if (this.flag){
                    this.loading2 = false
                }
            },
            // 周统计上报消除隐患
            async getdgeWeek(){
                const {data: res} = await this.$http.get('/danger/dgeWeek')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeWeek = res.data
                this.drawNewDge(res.data) // 新增隐患和消除隐患
                if (this.flag){
                    this.loading1 = false
                }
            },
            // 月统计上报消除隐患
            async getdgeMon(){
                const {data: res} = await this.$http.get('/danger/dgeMonth')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeMon = res.data

            },
            // 年统计上报消除隐患
            async getdgeYear(){
                const {data: res} = await this.$http.get('/danger/dgeYear')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeYear = res.data

            },
            // 日统计巡检时长
            async getchkDay(){
                const {data: res} = await this.$http.get('/check/chkDay')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.chkDay = res.data
                this.chkTime(res.data)
                if (this.flag){
                    this.loading3 = false
                }
            },
            // 周统计巡检时长
            async getchkWeek(){
                const {data: res} = await this.$http.get('/check/chkWeek')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.chkWeek = res.data

            },
            // 月统计上报消除隐患
            async getchkMon(){
                const {data: res} = await this.$http.get('/check/chkMonth')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.chkMon = res.data

            },
            // 初始化
            init(){
                var chartDom = document.getElementById('newDge');
                this.Dgechart = echarts.init(chartDom);
                this.ComChart = echarts.init(document.getElementById('myChart'))
                this.ChkChart = echarts.init(document.getElementById('chkTime'));
                this.flag = true
                },
            // 周月隐患上报对比
            drawDgeComp(dgeComp,type){
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.ComChart
                // 绘制图表
                var week = []
                var last = []
                var name1 = ''
                var name2 = ''
                if (type ==='week'){
                    week = dgeComp.thisWeek
                    last = dgeComp.lastWeek
                    name1 = '上周'
                    name2 = '本周'
                }
                else {
                    week = dgeComp.thisMonth
                    last = dgeComp.lastMonth
                    name1 = '上月'
                    name2 = '本月'
                }
                myChart.setOption({
                    // roseType: 'area',
                    // title: {
                    //     text: '隐患上报对比',
                    //     left: 'center'
                    // },
                    // default: 'pie',
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        // right: 'right',
                        bottom: 'bottom',

                    },
                    series: [
                        {
                            name: name1,
                            type: 'pie',
                            radius: '50%',
                            data: [
                                {value: last, name: name1},
                                {value: week, name: name2}
                                // {value: week, name: '本周'},
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            grid: {
                                bottom: 30
                            }
                        }

                    ]

                });
            },
            // 周月年新增消除隐患
            drawNewDge(list) {
                // var chartDom = document.getElementById('newDge');
                // var myChart = echarts.init(chartDom);
                var myChart = this.Dgechart
                var WeekOption;
                // var MonOption;
                // var YearOption;
                var dgeWeekTime = [];
                var dgeWeekCreate = [];
                var dgeWeekDone = [];
                list.forEach(function (item) {
                    dgeWeekCreate.push(item.create)
                    dgeWeekDone.push(item.done)
                    dgeWeekTime.push(item.time)
                })

                var emphasisStyle = {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0,0,0,0.3)'
                    }
                };

                WeekOption = {
                    legend: {
                        data: ['新增隐患',  '消除隐患'],
                        left: '10%'
                    },
                    // brush: {
                    //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
                    //     xAxisIndex: 0
                    // },
                    toolbox: {
                        feature: {
                            magicType: {
                                type: ['stack', 'tiled']
                            },
                            dataView: {}
                        }
                    },
                    tooltip: {},
                    xAxis: {
                        data: dgeWeekTime,
                        name: '隐患',
                        axisLine: {onZero: true},
                        splitLine: {show: false},
                        splitArea: {show: false}
                    },
                    yAxis: {},
                    grid: {
                        bottom: 30
                    },
                    series: [
                        {
                            name: '新增隐患',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: dgeWeekCreate
                        },
                        {
                            name: '消除隐患',
                            type: 'bar',
                            stack: 'one',
                            emphasis: emphasisStyle,
                            data: dgeWeekDone
                        }
                    ]
                };

                myChart.setOption(WeekOption);
            },
            // 巡检时长日周月
            chkTime(list){
                var myChart = this.ChkChart
                var option;
                var time = []
                var minute = []
                list.forEach(function (item) {
                    time.push(item.time)
                    minute.push(item.minute)
                })
                option = {
                    title: {
                        // text: '巡检时长统计',
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['最长时长']
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            dataView: {readOnly: false},
                            magicType: {type: ['line', 'bar']},
                            restore: {},
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: time
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    grid: {
                        bottom: 30
                    },
                    series: [
                        {
                            name: '最长时长',
                            type: 'line',
                            data: minute,
                            markPoint: {
                                data: [
                                    {type: 'max', name: '最大值'},
                                    {type: 'min', name: '最小值'}
                                ]
                            },
                            markLine: {
                                data: [
                                    {type: 'average', name: '平均值'}
                                ]
                            }
                        }

                    ]
                };

                option && myChart.setOption(option);
            }

        }
    }
</script>

<style scoped>
    .btn_jump{
        padding: 0;
        margin: 0;
        /*height: auto;*/
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    .center{
        text-align: center;
        align-self: center;
        justify-content: center;
        /*display: flex;*/
    }
    /deep/.header_card{
        /*margin-top: -5px;*/
        border-radius: 8px;
        height: 120px;
    }
    .header_num{
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
        line-height: 60px;
    }
    .body_num{
        margin-top: 10px;
        height: 30%;
    }
    .main_body{
        padding-bottom: 2px;
    }
    .chart{
        margin: auto;
        height: 28vh;
    }
    .main_title{
        font-size: 26px;
        font-weight: bold;
        color: #000000;
    }
</style>
