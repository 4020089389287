<template>
    <div style="height: 100%;width: 100%">
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dgeInfo'}">隐患列表</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">隐患录入</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!--            功能栏-->
            <el-row type="flex" align="middle">
                <el-col :span="8">
                    <el-button class="white_btn" @click="JumpTo()">
                        隐患列表
                    </el-button>
                    <el-button class="addcount" >
                        录入隐患
                    </el-button>
                </el-col>
                <el-col :offset="9" :span="3">
                    <el-button @click="DownloadExcel" class="addcount">
                        <i class="el-icon-box"></i>
                        导入模板下载
                    </el-button>
                </el-col>
                <el-col :offset="1" :span="3">
                    <el-upload
                            class="upload-demo"
                            action=""
                            :on-change="handleChange"
                            :show-file-list="false"
                            :auto-upload="false">
                        <el-button class="addcount">
                            <svg class="icon_add" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path d="M11.74125,13.83375 L7.8125,11.09375 L6.71875,11.09375 L6.71875,13.28125 L2.34375,13.28125 L2.34375,15.46875 L6.71875,15.46875 L6.71875,17.65625 L7.8125,17.65625 L11.74125,14.91625 C12.1875,14.63875 12.1875,14.11125 11.74125,13.83375 L11.74125,13.83375 Z M17.4075,6.46875 L12.47375,1.50125 C12.315,1.34 12.09875,1.25 11.87375,1.25 L4.53125,1.25 C3.82875,1.25 3.4375,1.635 3.4375,2.34375 L3.4375,10 L5.625,10 L5.625,3.4375 L11.09375,3.4375 L11.09375,6.71875 C11.09375,7.19125 11.71875,7.8125 12.1875,7.8125 L15.46875,7.8125 L15.48375,16.5625 L12.1875,16.5625 L12.1875,18.75 L16.5625,18.75 C17.265,18.75 17.65625,18.365 17.65625,17.65625 L17.65625,7.07375 C17.65625,6.84625 17.56625,6.62875 17.4075,6.46875 L17.4075,6.46875 Z" id="形状" fill="#FFFFFF"></path>
                            </svg>
                            Excel导入
                        </el-button>
                    </el-upload>
                </el-col>
            </el-row>
            <div class="dge_form">
                <el-form :model="dgeForm" ref="dgeFormRef"  status-icon label-position="left" inline label-width="80px">

                    <el-form-item label="隐患点名称" required prop="dgeName">
                        <el-input v-model="dgeForm.dgeName" placeholder="请输入隐患点名称" style="width: 400px"></el-input>
                    </el-form-item>
                    <br>
                    <el-form-item label="隐患类型:" required prop="dgeTypeId">
                        <el-select v-model="dgeForm.dgeTypeId"  placeholder="请选择隐患类型" style="width: 150px">
                            <el-option  v-for="(item,index) in TypeList" :key="index" :value="item.dgeTypeId" :label="item.dgeTypeName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="隐患等级：" style="margin-left: 14px" required prop="dgeLevel">
                        <el-select v-model="dgeForm.dgeLevel" style="width: 150px" placeholder="请选择隐患等级">
                            <el-option label="一般" value="一般"></el-option>
                            <el-option label="重要" value="重要"></el-option>
                            <el-option label="严重" value="严重"></el-option>
                        </el-select>
                    </el-form-item>
                    <br>
                    <el-form-item label="隐患发现者" required>
                        <el-autocomplete
                                :fetch-suggestions="querySearchAsync"
                                v-model="name"
                                style="width: 400px"
                                placeholder="请输入隐患发现者"
                                @select="handleSelect"
                        ></el-autocomplete>
                    </el-form-item>
                    <br>
                    <el-form-item label="所属部门：" prop="deptId">
                        <el-select v-model="dgeForm.deptId" style="width: 400px" placeholder="请选择所属部门">
                            <el-option v-for="(item1,index) in deptList" :key="index" :label="item1.deptName" :value="item1.deptId"></el-option>
                        </el-select>
                    </el-form-item>
                    <br>
                    <el-form-item label="隐患地址" required>
                        <el-autocomplete
                                v-model="address"
                                :fetch-suggestions="querySearch"
                                placeholder="请输入隐患地址(可直接输入选址，也可定点选址)"
                                style="width: 340px"
                                :trigger-on-focus="false"
                                @select="handleSelect1"
                        />
<!--                        <el-input v-model="address" placeholder="请输入隐患地址" style="width: 340px"></el-input>-->
                        <el-button type="text" @click="showMap=true">(定点选址)</el-button>
                    </el-form-item>
                    <br>
                    <el-form-item label="隐患描述：" prop="dgeInfo">
                        <el-input type="textarea"
                                  :rows="5" v-model="dgeForm.dgeInfo" style="width: 400px" placeholder="请输入隐患描述"></el-input>
                    </el-form-item>
                    <br>
                    <el-form-item>
                        <el-button class="addcount" style="margin-left: 80px" @click="AddDgeInfo()">保存</el-button>
                        <el-button class="white_btn" style="margin-left: 40px" @click="resetAdd()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <el-dialog
                title="上传隐患列表预览"
                :visible.sync="showList"
                width="68.6%"
                @close="JumpClosed">
            <el-table
                    height="280"
                    v-loading="loading1"
                    element-loading-text="拼命加载中"
                    show-overflow-tooltip
                    element-loading-spinner="el-icon-loading"
                    :data="tableData">
                <el-table-column fixed type="index" label="#" width="30px"></el-table-column>
                <el-table-column prop="dgeName" fixed label="隐患名称" align="center"></el-table-column>
                <el-table-column prop="dgeInfo" label="隐患信息" align="center"></el-table-column>
                <el-table-column prop="dgeTypeId" label="类型编号" align="center"></el-table-column>
                <el-table-column prop="dgeLevel" label="隐患等级" align="center"></el-table-column>
                <el-table-column prop="dgeLng" label="隐患经度" align="center"></el-table-column>
                <el-table-column prop="dgeLat" label="隐患纬度" align="center"></el-table-column>
                <el-table-column prop="dgeFound" label="发起人编号" align="center"></el-table-column>
                <el-table-column prop="deptId" label="部门编号" align="center"></el-table-column>
            </el-table>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
            <el-row :gutter="20">
                <el-col :span="4" :offset="5">
                      <el-button class="white_btn" @click="JumpClosed">取 消</el-button>
                </el-col>
                <el-col :span="4" :offset="5">
                      <el-button class="addcount" @click="AddDgeList">导 入</el-button>
                </el-col>
            </el-row>
        </span>
        </el-dialog>
        <el-dialog
                title="地图"
                :visible.sync="showMap"
                width="47.9%"
                :append-to-body="true"
                @closed="closeMap"
                style="height: 600px; overflow: hidden;"
                :close-on-click-modal="false" :close-on-press-escape="false"
        >

            <el-input class="addressInput" v-model="addressKeyword" placeholder="模糊搜索地址，定点选址，在地图上点击关键点，确定即可"></el-input>
            <el-row>
                <el-col :span="4" :offset="6">
                    <el-button class="addcount" @click="checkedAddress">确 定</el-button>
                </el-col>
                <el-col :span="4" :offset="4">
                    <el-button class="white_btn" @click="closeMap">关 闭</el-button>
                </el-col>
            </el-row>
            <baidu-map :center="center" ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I" :zoom="zoom" @ready="handler" style="height:1080px" @click="getClickInfo" :scroll-wheel-zoom='true'
                       v-loading='loadingMap' element-loading-text="拼命加载中"
                       element-loading-spinner="el-icon-loading">
                <bm-view style="width: 100%; height:420px; flex: 1"></bm-view>
                <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
            </baidu-map>
        </el-dialog>
        <div>
            <baidu-map style="width: 0px;height: 0px" :center="mapCenter" :zoom="mapZoom" :scroll-wheel-zoom="true" ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I" @ready="handlerBMap" />
        </div>
    </div>

</template>

<script>
    import {BaiduMap, BmView, BmLocalSearch,} from "vue-baidu-map/components";
    var fileDownload = require("js-file-download");
    export default {
        name: "AddDge",
        components: {
            BaiduMap,BmView, BmLocalSearch,
        },
        data(){
            return{
                dgeForm:{
                    dgeName:'',
                    dgeInfo: '',
                    dgeTypeId: '',
                    dgeLevel: '',
                    dgeCycle: '',
                    dgeLng: '',
                    dgeLat: '',
                    dgeFound: '',
                    deptId: ''
                },
                address: '',
                TypeList: [],
                deptList: [],
                // 从服务器请求
                users: [],
                name: '',
                timeout:  null,
                showMap: false,
                // 地图相关
                loadingMap: true,
                BMap: '',
                map: '',
                geoc: '',
                addressKeyword: '',
                pointLngLat: '',
                center: { lng:121.41003, lat:29.65537 },
                zoom: 13,
                mapZoom: 15,
                mapCenter: { lng: 0, lat: 0 },
                mapLocation: {
                    address: undefined,
                    coordinate: undefined
                },
            //    excel
                tableData: [],
                fileContent: '',
                file: '',
                data: '',
                showList: false,
                loading1 : true
            }
        },
        created() {
            this.getDgeType()
            this.getDeptList()
            this.loadAll()
        },
        methods:{
            //    excel
            handleDelete (item) {
                console.log('handleDelete', item)
            },
            handelEdit (item) {
                console.log('handleDelete', item)
            },
// 核心部分代码(handleChange 和 importfile)
            handleChange (file, fileList) {
                console.log(fileList)
                this.fileContent = file.raw
                const fileName = file.name
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
                if (this.fileContent) {
                    if (fileType === 'xlsx' || fileType === 'xls') {
                        this.tableData = []
                        this.importfile(this.fileContent)
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '附件格式错误，请重新上传！'
                        })
                    }
                } else {
                    this.$message({
                        type: 'warning',
                        message: '请上传附件！'
                    })
                }
            },
            importfile (obj) {
                this.showList = true
                const reader = new FileReader()
                const _this = this
                reader.readAsArrayBuffer(obj)
                reader.onload = function () {
                    const buffer = reader.result
                    const bytes = new Uint8Array(buffer)
                    const length = bytes.byteLength
                    let binary = ''
                    for (let i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i])
                    }
                    const XLSX = require('xlsx')
                    const wb = XLSX.read(binary, {
                        type: 'binary'
                    })
                    console.log('wb' + wb)
                    const outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
                    this.data = [...outdata]
                    console.log(this.data)
                    const arr = []
                    this.data.map(v => {
                        const obj = {}
                        obj.dgeName = v.隐患名称
                        obj.dgeInfo = v.隐患信息
                        obj.dgeTypeId = v.隐患类型编号
                        obj.dgeLevel = v.隐患等级
                        obj.dgeLng = v.隐患经度
                        obj.dgeLat = v.隐患纬度
                        obj.dgeFound = v.发起人编号
                        obj.deptId = v.部门编号
                        obj.dgeCycle = ''
                        arr.push(obj)
                    })
                    _this.tableData = _this.tableData.concat(arr)
                    if (_this.tableData.length === arr.length){
                        _this.loading1 = false
                    }
                }
            },
            // 关闭
            JumpClosed () {
                this.showList = false
            },
            //
            resetAdd () {
                this.$refs.dgeFormRef.resetFields()
                this.address = ''
                this.name = ''
            },
            // 获取隐患所有类型
            async getDgeType () {
                const { data: res } = await this.$http.get('/type/allType')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.TypeList = res.data
            },
            // 获取所有部门
            async getDeptList () {
                const { data: res } = await this.$http.get('/dept/allDept')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList= res.data
            },
            async loadAll() {
                // const { data: res } = await this.$http.get('/emp/showByDept/'+ this.dgeForm.deptId)
                const { data: res } = await this.$http.get('/emp/allEmp')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                var arr = []
                res.data.forEach(function (item) {
                    arr.push({'empId': item.empId , 'value': item.empName})
                })
                this.users  = arr
                // console.log(arr)
                return arr
            },
            // 远程获取数据
            querySearchAsync(queryString, cb) {
                var users = this.users;
                // console.log(users)
                var results = queryString ? users.filter(this.createStateFilter(queryString)) : users;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    cb(results);
                }, 3000 * Math.random());
            },
            createStateFilter(queryString) {
                return (state) => {
                    return (String(state.value).toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            handleSelect(item) {
                this.dgeForm.dgeFound = item.empId
                // console.log(item);
            },

            async AddDgeInfo(){
                // console.log(this.dgeForm)
                const { data: res } = await this.$http.post('/danger/create', this.dgeForm)
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success( '创建隐患成功!')
                this.resetAdd()
            },
            // 模板下载
            DownloadExcel () {
                let strurl = '/file/隐患批量导入模板.xlsx'
                var index = strurl.lastIndexOf('/file/')
                var url = strurl.substring(index,strurl.length)
                let name = strurl.substring(index+5,strurl.length)
                console.log(url)
                console.log(name)
                return new Promise(() => {
                    this.$http.get(url,{ responseType: 'arraybuffer'}).then(res => {
                        // resolve(res)
                        let fileName = name
                        fileDownload(res.data,fileName);
                    }).catch(error => {
                        this.$message.error('下载失败!该模板不见啦！')
                        console.log(error)
                    })
                })
            },
            // excel导入
            async AddDgeList(){
                console.log(this.tableData)
                const { data: res } = await this.$http.post('/danger/createMore', this.tableData)
                console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success( '导入隐患列表成功!')
                this.showList = false
                this.tableData = []
                this.loading1 = true
            },
            JumpTo(){
                this.$router.push('/dgeInfo')
            },
            // 地图初始化
            handler ({BMap, map}) {
                this.mapOpen()
                this.BMap = BMap
                this.map = map
                this.loadingMap = true
                var geolocation = new BMap.Geolocation()
                this.geoc = new BMap.Geocoder() // 地址解析对象
                var $this = this
                // 调用百度地图api 中的获取当前位置接口
                geolocation.getCurrentPosition(function (r) {
                    let myGeo = new BMap.Geocoder()
                    myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function (result) {
                        if (result) {
                            $this.loadingMap = false
                            // console.log(result)
                            $this.$set($this, 'pointLngLat', {lng: result.point.lng, lat: result.point.lat})
                            map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放,默认关闭
                            $this.addPoint({BMap, map})
                        }
                    })
                })
            },
// 添加点位
            addPoint ({BMap, map}) {
                map.clearOverlays()
                var point = new BMap.Point(this.pointLngLat.lng, this.pointLngLat.lat)
                let zoom = map.getZoom()
                // console.log('zoom:' + zoom)
                setTimeout(() => {
                    map.centerAndZoom(point, zoom)
                }, 0)
                var marker = new BMap.Marker(point) // 创建标注
                map.addOverlay(marker) // 将标注添加到地图中
            },
            // 点击地图
            getClickInfo (e) {
                // console.log(e)
                this.center = {lng: e.point.lng, lat: e.point.lat}
                this.pointLngLat = {lng: e.point.lng, lat: e.point.lat}
                // 获取点位信息
                let $this = this
                this.geoc.getLocation(e.point, function (rs) {
                    var addComp = rs.addressComponents
                    $this.pointAddress = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber
                    // console.log($this.pointAddress)
                })
            },
            async checkedAddress(){
                const confirmResult = await this.$confirm('该操作将把您最后一次鼠标点击的点当作隐患地址，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => err)
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消')
                }
                this.address = this.pointAddress
                // 将百度地图经纬度转为腾讯地图进行存储
                var lng = this.pointLngLat.lng
                var lat = this.pointLngLat.lat
                // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                // let x = lng - 0.0065;
                // let y = lat - 0.006;
                // let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                // let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                // let lngs = z * Math.cos(theta);
                // let lats = z * Math.sin(theta);
                this.dgeForm.dgeLng = lng
                this.dgeForm.dgeLat = lat                // console.log(this.pointLngLat,1)
                this.closeMap()
                // console.log(this.dgeForm)
            },
            // 地图相关
            mapOpen () {
                this.top = this.getScrollTop()
                if (this.top) {
                    this.setScrollTop(0)
                }
            },
// 关闭地图后调用
            closeMap () {
                this.setScrollTop(this.top)
                this.top = 0
                this.showMap = false
            },
            getScrollTop () {
                let scrollTop = 0
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop
                } else if (document.body) {
                    scrollTop = document.body.scrollTop
                }
                return scrollTop
            },
            setScrollTop (top) {
                if (!isNaN(top)) {
                    if (document.documentElement && document.documentElement.scrollTop !== undefined) {
                        document.documentElement.scrollTop = top
                    } else if (document.body) {
                        document.body.scrollTop = top
                    }
                }
            },
            handlerBMap({ BMap, map }) {
                this.BMap = BMap
                this.map = map
                if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
                    this.mapCenter.lng = this.mapLocation.coordinate.lng
                    this.mapCenter.lat = this.mapLocation.coordinate.lat
                    // this.mapZoom = 15
                    map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
                } else {
                    this.mapCenter.lng = 113.271429
                    this.mapCenter.lat = 23.135336
                    // this.mapZoom = 10
                }
            },
            querySearch(queryString, cb) {
                var that = this
                var myGeo = new this.BMap.Geocoder()
                myGeo.getPoint(queryString, function(point) {
                    if (point) {
                        that.mapLocation.coordinate = point
                        // that.makerCenter(point)
                    } else {
                        that.mapLocation.coordinate = null
                    }
                }, this.locationCity)
                var options = {
                    onSearchComplete: function(results) {
                        if (local.getStatus() === 0) {
                            // 判断状态是否正确
                            var s = []
                            for (var i = 0; i < results.getCurrentNumPois(); i++) {
                                var x = results.getPoi(i)
                                var item = { value: x.address + x.title, point: x.point }
                                s.push(item)
                                cb(s)
                            }
                        } else {
                            cb()
                        }
                    }
                }
                var local = new this.BMap.LocalSearch(this.map, options)
                local.search(queryString)
            },
            handleSelect1(item) {
                var { point } = item
                this.mapLocation.coordinate = point
                // console.log(point)
                // 将百度地图经纬度转换为腾讯地图经纬度
                var lng = point.lng
                var lat = point.lat
                // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                // let x = lng - 0.0065;
                // let y = lat - 0.006;
                // let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                // let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                // let lngs = z * Math.cos(theta);
                // let lats = z * Math.sin(theta);
                this.dgeForm.dgeLng = lng
                this.dgeForm.dgeLat = lat
                // console.log(this.dgeForm)
                // this.makerCenter(point)
            },
            // 设置搜索框输入中心
            // makerCenter(point) {
            //     if (this.map) {
            //         this.map.clearOverlays()
            //         this.map.addOverlay(new this.BMap.Marker(point))
            //         this.mapCenter.lng = point.lng
            //         this.mapCenter.lat = point.lat
            //         this.mapZoom = 15
            //     }
            // }
        },
        mounted() {
            this.users = this.loadAll();
            // console.log(this.users)
        }
    }
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .white_btn{
        letter-spacing: 2px;
        color: #009199;
    }
    .white_btn:hover,.white_btn:focus{
        background: #00B6C0;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .icon_add{
        width: 20px;
        height: 20px;
        padding-right: 2px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    .dge_form{
        margin-left: 75px;
        margin-top: 60px;
    }
</style>

<style lang="less" scoped>
    .el-button+.el-button{
        margin-left: 0px;
    }

</style>
