<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/Static'}">数据统计</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">详情页面</span></el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <!--            功能栏-->
            <el-row type="flex" align="middle">
                <el-col :span="76" style="font-weight: bold">{{this.title}}</el-col>
                <el-col :span="10" :offset="2">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getDgeList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :offset="2" :span="3">
                    <el-button class="addcount" @click="handleExport">
                        <svg class="icon_add" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M10.0088731,5.83850932 L18.2608696,14.1259982 L14.72937,14.1259982 L14.72937,20 L5.27062998,20 L5.25288375,14.1259982 L1.77462287,14.1259982 L10.0088731,5.83850932 Z M19.4321207,0 L19.4321207,2.36024844 L0.567879336,2.36024844 L0.567879336,0 L19.4321207,0 Z" id="形状" fill="#FFFFFF"></path>
                        </svg>
                        导出隐患
                    </el-button>
                </el-col>
            </el-row>
            <div>
            <el-scrollbar :native="false">

            <!--           展示所有隐患-->
             <el-table
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        id="exportExcel"
                        ref="multipleTable"
                        :data="pagetestlist"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="{color: '#000000E6'}"
                        @selection-change="handleSelectionChange"
                        :row-key="getRowKeys"
                        :expand-row-keys="expands"
                        @expand-change="expandChange">
                    <!--                多选-->
                    <el-table-column
                            fixed="left"
                            type="selection"
                            width="30">
                    </el-table-column>
                    <!--                展开行-->
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-form label-position="left"  style="margin-left: 40px">
                                <el-form-item >
                                 <span slot="label">
                                     <i class="el-icon-bank-card"></i>
                                     隐患名称
                                </span>
                                    <span style="color: #009199">{{scope.row.dgeName}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                 <i class="el-icon-house"></i>
                                 所属部门
                                </span>
                                    <span style="color: #0077aa">{{dgeList.deptName}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-user-solid"></i>
                                     上报人
                                </span>
                                    <span>{{dgeList.founder}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-location"></i>
                                     地址
                                </span>
                                    <span>{{dgeList.address}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-time"></i>
                                     巡视周期
                                </span>
                                    <span>{{scope.row.dgeCycle ? scope.row.dgeCycle + '(天)' : '暂无设定'}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-location"></i>
                                     隐患状态
                                </span>
                                    <span v-if="scope.row.dgeStatus === 'no'" style="color: orange">已上报但未审核</span>
                                    <span v-else-if="scope.row.dgeStatus === 'yes'" style="color: #009199">已上报并审核通过</span>
                                    <span v-else-if="scope.row.dgeStatus === 'fail'" style="color: red">上报审核不通过</span>
                                    <span v-else-if="scope.row.dgeStatus === 'done'" style="color: darkseagreen">消除隐患已审核</span>
                                    <span v-else-if="scope.row.dgeStatus === 'undone'" style="color: darkorange">消除隐患未审核</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-info"></i>
                                     隐患描述
                                </span>
                                    <span>{{dgeList.info}}</span>
                                </el-form-item>
                                <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-picture"></i>
                                     现场照片
                                </span>
                                    <div v-if="dgeList.img[0] === 'null'">
                                        <el-col :span="3">
                                            <svg class="Xc_pic" viewBox="0 0 102 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <path d="M21.1470999,3.91629139 L3.61938683,3.91629139 C2.99339708,3.91629139 2.47787611,4.43797648 2.47787611,5.07145122 L2.47787611,19.40537 C2.47787611,20.0388447 2.99339708,20.5605298 3.61938683,20.5605298 L21.1593742,20.5605298 C21.785364,20.5605298 22.300885,20.0388447 22.300885,19.40537 L22.300885,5.07145122 C22.2886106,4.43797648 21.785364,3.91629139 21.1470999,3.91629139 Z M20.5456588,17.8651569 C20.5456588,17.5794722 20.5456588,18.7843163 19.5514398,18.7843163 L4.98183512,18.7843163 C4.22082797,18.7843163 4.22082797,18.1632626 4.22082797,18.1632626 L4.22082797,17.0081028 C4.22082797,17.0081028 4.45403984,16.6603127 4.68725171,16.4243123 L8.1363325,12.9339907 C8.1363325,12.9339907 8.29589852,12.80978 8.41864161,12.80978 C8.553659,12.80978 8.72549933,12.9464118 8.72549933,12.9464118 L10.7875832,15.0579943 C10.9348749,15.2070471 11.1680868,15.2070471 11.3153785,15.0579943 L15.7709526,10.4994603 C15.7709526,10.4994603 15.9305186,10.3628285 16.1146333,10.3628285 C16.2987479,10.3628285 16.4583139,10.5118814 16.4583139,10.5118814 L20.079235,14.1760981 C20.3124469,14.3996774 20.5456588,14.7350464 20.5456588,14.7350464 L20.5456588,17.8651569 L20.5456588,17.8651569 Z" id="形状" fill="#DCE0E6"></path>
                                                <path d="M5.45132743,8.07735099 C5.45132743,8.75866457 6.01331995,9.30119205 6.69026549,9.30119205 C7.37998358,9.30119205 7.92920354,8.74604765 7.92920354,8.07735099 C7.92920354,7.39603741 7.36721102,6.85350993 6.69026549,6.85350993 C6.01331995,6.85350993 5.45132743,7.39603741 5.45132743,8.07735099 L5.45132743,8.07735099 Z" id="路径" fill="#FFFFFF"></path>
                                            </svg>
                                        </el-col>
                                        暂无
                                    </div>
                                    <div v-else>
                                        <br>
                                        <div  v-for="(src,index) in dgeList.img" :key="index">
                                            <el-col :span="4" class="img_pad">
                                                <viewer :images="dgeList.img" class="XC_pic">
                                                    <img :src="src" style="width:100%;">
                                                </viewer>
                                            </el-col>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dgeName" label="隐患点名称" align="center"></el-table-column>
                    <el-table-column prop="dgeTypeName" label="隐患类型" align="center">
                        <template slot-scope="scope">
                            <i class="el-icon-collection-tag"></i>
                            {{scope.row.dgeTypeName}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="dgeLevel" label="隐患等级" align="center" width="120">
                        <template slot-scope="scope">
                            <el-tag class="normal" v-if="scope.row.dgeLevel == '一般'">
                                {{scope.row.dgeLevel}}
                            </el-tag>
                            <el-tag class="serious" v-if="scope.row.dgeLevel == '严重'">
                                {{scope.row.dgeLevel}}
                            </el-tag>
                            <el-tag class="important" v-if="scope.row.dgeLevel == '重要'">
                                {{scope.row.dgeLevel}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateTime" sortable label="上报时间" align="center">
                        <template slot-scope="scope">
                            <i class="el-icon-date"></i>
                            {{scope.row.updateTime}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Countdown" sortable label="距下次巡检" align="center">
                        <template slot-scope="scope">
                        <span style="color: #969896" v-if="scope.row.Countdown === null">
<!--                            <i class="el-icon-alarm-clock"></i>-->
                            暂无巡检
                        </span>
                            <span style="color: red;" v-if="scope.row.Countdown < 0">
                            <i class="el-icon-alarm-clock"></i>
                            已超出{{scope.row.Countdown * (-1)}}天
                        </span>
                            <span style="color: #009199" v-if="scope.row.Countdown > 0">
                            <i class="el-icon-alarm-clock"></i>
                            剩余{{scope.row.Countdown}}天
                        </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center"  fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="EditDgeInfo(scope.row)" style="color: #009199">编辑</el-button>
                            <el-button type="text" @click="removeDge(scope.row.dgeId)" style="color: red; padding-left: 4px">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>

            </el-scrollbar>
            </div>
        </el-card>
        <el-dialog
                title="修改隐患信息"
                :visible.sync="ToOpenUp"
                width="47.9%" @close="DgeDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="dgeForm" ref="dgeFormRef"  status-icon label-width="100px">
                <el-form-item label="隐患点名称: " required prop="dgeName">
                    <el-input v-model="dgeForm.dgeName" placeholder="请输入隐患点名称"></el-input>
                </el-form-item>
                <el-form-item label="隐患地址: " required>
                    <el-autocomplete
                            v-model="address"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入隐患地址(可直接输入选址，也可定点选址)"
                            :trigger-on-focus="false"
                            @select="handleSelect1"
                            style="width: 80%"
                    />
                    <el-button type="text" @click="showMap=true">定点选址</el-button>
                </el-form-item>
                <el-form-item label="隐患类型: " required prop="dgeTypeId">
                    <el-select v-model="dgeForm.dgeTypeId"  placeholder="请选择隐患类型">
                        <el-option  v-for="(item,index) in TypeList" :key="index" :value="item.dgeTypeId" :label="item.dgeTypeName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="隐患等级: " required prop="dgeLevel">
                    <el-select v-model="dgeForm.dgeLevel" placeholder="请选择隐患等级">
                        <el-option label="一般" value="一般"></el-option>
                        <el-option label="重要" value="重要"></el-option>
                        <el-option label="严重" value="严重"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门: " prop="deptId" required>
                    <el-select v-model="dgeForm.deptId" placeholder="请选择所属部门">
                        <el-option v-for="(item1,index) in deptList" :key="index" :label="item1.deptName" :value="item1.deptId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  label="隐患描述: " prop="dgeInfo">
                    <el-input type="textarea"
                              :rows="5" v-model="dgeForm.dgeInfo" placeholder="请输入隐患描述"></el-input>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="5">
                        <el-button class="addcount" @click="EditDgeForm()">保 存</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="white_btn" style="margin-left: 40px" @click="resetAdd()">重 置</el-button>
                    </el-col>
                </el-row>
            </span>
            <el-dialog
                    title="地图"
                    :visible.sync="showMap"
                    width="47.9%"
                    :append-to-body="true"
                    @closed="closeMap"
                    style="height: 600px; overflow: hidden;"
                    :close-on-click-modal="false" :close-on-press-escape="false"
            >
                <el-input class="addressInput" v-model="addressKeyword" placeholder="模糊搜索地址，定点选址，在地图上点击关键点，确定即可"></el-input>
                <el-row>
                    <el-col :span="4" :offset="6">
                        <el-button class="addcount" @click="checkedAddress">确 定</el-button>
                    </el-col>
                    <el-col :span="4" :offset="4">
                        <el-button class="white_btn" @click="closeMap">关 闭</el-button>
                    </el-col>
                </el-row>
                <baidu-map :center="center" ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I" :zoom="zoom" @ready="handler" style="height:1080px" @click="getClickInfo" :scroll-wheel-zoom='true'
                           v-loading='loadingMap'
                           element-loading-text="拼命加载中"
                           element-loading-spinner="el-icon-loading">
                    <bm-view style="width: 100%; height:420px; flex: 1"></bm-view>
                    <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
                </baidu-map>

            </el-dialog>
        </el-dialog>
        <div>
            <baidu-map style="width: 0px;height: 0px" :center="mapCenter" :zoom="mapZoom" :scroll-wheel-zoom="true" ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I" @ready="handlerBMap" />
        </div>
        <baidu-map ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I"></baidu-map>
    </div>
</template>
<script>
    import {BaiduMap, BmLocalSearch, BmView} from "vue-baidu-map/components";
    import FileSaver from 'file-saver'
    import XLSX from 'xlsx'
    export default {
        name: "dgeInfo",
        components: {
            BaiduMap,BmView, BmLocalSearch
        },
        data(){
            return{
                baseUrl: "http://ele.qiniu.heilama.com/",
                loading:true,
                //设置row-key只展示一行
                expands: [],
                getRowKeys(row) {//只展开一行放入当前行id
                    return row.dgeId
                },
                queryInfo: {
                    // 模糊搜索
                    searchInfo: '',
                    // 当前页数
                    pagenum: 1,
                    pagesize: 10
                },
                total: 0,
                AllDgelist: [], // 所有隐患
                founder : '', // 上报者
                dgeList: {
                    founder: '', // 上报人
                    deptName: '', // 部门名称
                    address: '', // 隐患地址
                    info: '', // 隐患文字描述
                    img: '', //现场照片
                },
                dgeForm:{
                    dgeId: '',
                    dgeName: '',
                    degInfo: '',
                    dgeTypeId: '',
                    dgeCycle: '',
                    dgeLevel: '',
                    dgeLng: '',
                    dgeLat: '',
                    deptId: ''
                },
                ToOpenUp: false, // 更新隐患窗口
                address: '', // 地址
                deptList: [],
                TypeList: [],
                showMap: false,
                // 地图相关
                loadingMap: true,
                BMap: '',
                map: '',
                geoc: '',
                addressKeyword: '',
                pointLngLat: '',
                center: { lng:121.41003, lat:29.65537 },
                zoom: 13,
                selected: '',
                title: '',
                mapZoom: 15,
                mapCenter: { lng: 0, lat: 0 },
                mapLocation: {
                    address: undefined,
                    coordinate: undefined
                },
            }
        },
        created() {
            this.getType()
            this.getDgeType()
            this.getDeptList()
        },
        methods:{
            getType(){
              var type = this.$route.query.type
              // console.log(this.$route.query.type)
              this.getDgeList(type)
                if (type === 'all'){
                    this.title = '当前隐患总数'
                }
                else if (type === 'serious'){
                    this.title = '严重隐患列表'
                }
                else if (type === 'important'){
                    this.title = '重要隐患列表'
                }
                else if (type === 'normal'){
                    this.title = '一般隐患列表'
                }
                else if (type === 'TodayAdd'){
                    this.title = '今日上报隐患列表'
                }
                else if (type === 'TodayDel'){
                    this.title = '今日消除隐患列表'
                }
                else if (type === 'noCheck'){
                    this.title = '待审核隐患列表'
                }
            },
            // 导出excel
            async handleExport() {
                const confirmResult = await this.$confirm('该操作将导出当前页面隐患列表数据，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => err)
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消导出')
                }
                let data = this.tableContent;
                this.tableContent = this.AllDgelist;//把不分页的所有数据都渲染到表格中
                var fix = document.querySelector('.el-table__fixed-right');//删除fixed的dom节点
                let wb;
                this.$nextTick(() => {
                    if (fix) {
                        wb = XLSX.utils.table_to_book(document.querySelector('#exportExcel').removeChild(fix));
                        document.querySelector('#exportExcel').appendChild(fix);//恢复删除fixed的dom节点
                    } else {
                        wb = XLSX.utils.table_to_book(document.querySelector('#exportExcel'));
                    }
                    let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
                    try {
                        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), "当前隐患列表.xlsx")
                    } catch (e) {
                        if (typeof console !== 'undefined') console.log(e, wbout)
                    }
                    this.tableContent = data;//恢复表格的分页数据
                    return wbout;
                })
    },
            // 获取隐患所有类型
            async getDgeType () {
                const { data: res } = await this.$http.get('/type/allType')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.TypeList = res.data
            },
            // 获取所有部门
            async getDeptList () {
                const { data: res } = await this.$http.get('/dept/allDept')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList= res.data
            },
            // 获取所有隐患
            async getDgeList (type) {
                const { data: res } = await this.$http.get('/danger/findAll')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                var arr = []
                var list = res.data
                var nowTime = new Date();
                if (type === 'all'){
                    list.forEach(function (item) {
                        if (item.dgeStatus !== 'fail' && item.dgeStatus !== 'no') {
                            if(item.chkTime){
                                var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})
                        }
                    })
                }
                else if (type === 'serious') {
                    list.forEach(function (item) {
                        if (item.dgeLevel === '严重') {
                            if(item.chkTime){
                                var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})

                        }
                    })
                }
                else if (type === 'important') {
                    list.forEach(function (item) {
                        if (item.dgeLevel === '重要') {
                            if(item.chkTime){
                                var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})

                        }
                    })
                }
                else if (type === 'normal') {
                    list.forEach(function (item) {
                        if (item.dgeLevel === '一般') {
                            if(item.chkTime){
                                var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})
                        }
                    })
                }
                else if (type === 'TodayAdd') {
                    // console.log(nowTime.getDay())
                    list.forEach(function (item) {
                        var time1 = item.createTime.substring(0,19).replace(/-/g,'/')
                        var date1 = new Date(time1)
                        nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                        date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
                        const diff1 = nowTime.getTime() - date1.getTime(); //目标时间减去当前时间
                        var day1 = diff1 / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
                        if ( day1 === 0) {
                            if(item.chkTime){
                                var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})
                        }
                    })
                }
                else if (type === 'TodayDel') {
                    // var nowTime = new Date();
                    list.forEach(function (item) {
                        if (item.endTime){
                            var time1 = item.endTime.substring(0,19).replace(/-/g,'/')
                            var date1 = new Date(time1)
                            nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                            date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
                            const diff1 = nowTime.getTime() - date1.getTime(); //目标时间减去当前时间
                            var day1 = diff1 / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
                            if (day1 === 0) {
                                if(item.chkTime){
                                    var time = item.chkTime.substring(0,19).replace(/-/g,'/')
                                    var date = new Date(time)
                                    nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                    const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                    var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                    // console.log(Countdown)
                                }
                                // 上报隐患未通过
                                else {
                                    Countdown = null
                                }
                                arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                    createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                    dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                    dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                    dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                    endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})
                            }
                        }
                    })
                }
                else if (type === 'noCheck') {
                    list.forEach(function (item) {
                        if (item.dgeStatus === 'no') {
                            if(item.chkTime){
                                var time = item.createTime.substring(0,19).replace(/-/g,'/')
                                var date = new Date(time)
                                nowTime = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());
                                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                                const diff = nowTime.getTime() - date.getTime(); //目标时间减去当前时间
                                var Countdown =item.dgeCycle - diff / (24 * 60 * 60 * 1000)  ;  //计算当前时间与结束时间之间相差天数
                                // console.log(Countdown)
                            }
                            // 上报隐患未通过
                            else {
                                Countdown = null
                            }
                            arr.push({Countdown:Countdown,chkNum:item.chkNum,chkTime:item.chkTime,
                                createTime:item.createTime,deptId:item.deptId,dgeCycle:item.dgeCycle,
                                dgeFound:item.dgeFound,dgeId:item.dgeId,dgeInfo:item.dgeInfo,dgeJudge:item.dgeJudge,
                                dgeLat:item.dgeLat,dgeLng:item.dgeLng,dgeLevel:item.dgeLevel, dgeName:item.dgeName,
                                dgeStatus:item.dgeStatus,dgeTypeId:item.dgeTypeId,dgeTypeName:item.dgeTypeName,
                                endTime:item.endTime,refuse:item.refuse,submit:item.submit,updateTime:item.updateTime})
                        }
                    })
                }
                this.AllDgelist = arr
                this.total =arr.length
                if (this.AllDgelist.length === arr.length){
                    this.loading = false
                }
            },
            // 选中该行
            handleSelectionChange(val) {
                this.selected = val;
                // console.log(val)
            },
            // 监听 pagesize 改变的事件
            handleSizeChange (newSize) {
                this.queryInfo.pagesize = newSize
                this.getDgeList()
            },
            // 监听页码值改变的事件
            handleCurrentChange (newPage) {
                // console.log(newPage)
                this.queryInfo.pagenum = newPage
                this.getDgeList()
            },
            // 展开事件----动态获取内嵌表数据
            expandChange(row, expandedRows) {
                // console.log(expandedRows.length)
                // console.log(row)
                var that = this
                if (expandedRows.length) {
                    that.expands = []
                    if (row) {
                        that.expands.push(row.dgeId)// 每次push进去的是每行的ID
                        var FounderId = row.dgeFound
                        that.getDgeFounder(FounderId)
                        // 将腾讯地图经纬度转换为百度地图经纬度
                        var dgeLng = row.dgeLng //经度
                        var dgeLat = row.dgeLat
                        // var x_pi = 3.14159265358979324;
                        // var x = parseFloat(dgeLng);
                        // var y = parseFloat(dgeLat);
                        // var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                        // var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                        // var lng = (z * Math.cos(theta) + 0.0065);
                        // var lat = (z * Math.sin(theta) + 0.006);
                        // 调用百度地图api解析经纬度得到地址
                        that.getAddressName(dgeLng, dgeLat)
                        that.getDeptName(row.deptId)
                        var info = (row.dgeInfo+ "").split('imgGroup:')[0]
                        that.dgeList.info = (info === 'null') ? '暂无隐患信息' : info
                        var imgstr = (row.dgeInfo+ "").split('imgGroup:')[1]
                        if(imgstr) {
                            var arr = (imgstr+ "").split(",")
                            var list = []
                            arr.forEach(function (item) {
                                list.push(that.baseUrl + item)
                            })
                            that.dgeList.img = list
                        }
                        else {
                            that.dgeList.img = ["null"]
                        }
                    }
                } else {
                    that.expands = []// 默认不展开
                }
            },
            //  获取上报人名字
            async getDgeFounder (id) {
                const { data: res } = await this.$http.get('/emp/showInfo/' + id)
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeList.founder = res.data.empName
                // console.log(this.dgeList.founder)
            },
            //  获取部门名称
            async getDeptName (id) {
                const { data: res } = await this.$http.get('/dept/showInfo/' + id)
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.dgeList.deptName = res.data.deptName
            },
            DgeDialogClosed () {
                this.$refs.dgeFormRef.resetFields()
                this.address = ''
            },
            EditDgeInfo (list){
                // console.log(list)
                this.ToOpenUp = true
                // this.dgeForm = list
                this.dgeForm.dgeId = list.dgeId
                this.dgeForm.dgeName = list.dgeName
                this.dgeForm.degInfo = list.dgeInfo
                this.dgeForm.dgeTypeId = list.dgeTypeId
                this.dgeForm.dgeCycle = list.dgeCycle
                this.dgeForm.dgeLevel = list.dgeLevel
                this.dgeForm.dgeLng = list.dgeLng
                this.dgeForm.dgeLat = list.dgeLat
                this.dgeForm.deptId = list.deptId
                // 将腾讯地图经纬度转换为百度地图经纬度
                // var x_pi = 3.14159265358979324;
                // var x = parseFloat(list.dgeLng);
                // var y = parseFloat(list.dgeLat);
                // var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                // var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                // var lng = (z * Math.cos(theta) + 0.0065);
                // var lat = (z * Math.sin(theta) + 0.006);
                // console.log(lng,lat)
                var lng = list.dgeLng
                var lat = list.dgeLat
                this.getAddressName(lng,lat)
            },
            resetAdd () {
                this.$refs.dgeFormRef.resetFields()
                // this.address = ''
            },
            async EditDgeForm () {
                // console.log(this.dgeForm.dgeCycle)
                const { data: res } = await this.$http.put('/danger/update', this.dgeForm)
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success( '编辑隐患成功!')
                this.ToOpenUp = false
                this.getDgeList()
            },
            //    删除隐患
            async removeDge (id) {
                // console.log(id)
                const confirmResult = await this.$confirm('此操作将永久删除该隐患, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).catch(err => err)
                // 若用户确认删除，则返回值为字符串 confirm
                // 若取消删除，返回 cancel
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除')
                }
                // console.log('确认删除')
                const { data: res } = await this.$http.delete('/danger/delete/' + id)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('删除隐患成功!')
                this.getDgeList()
            },
            // 根据坐标逆解析地址
            getAddressName(lng,lat){
                let that = this
                // 创建解析器
                var myGeo = new window.BMap.Geocoder();
                var pt = new window.BMap.Point(lng,lat);// 随便写个经纬度
                myGeo.getLocation(pt,function(result){
                    // console.log(result);
                    that.address = result.address
                    that.dgeList.address = result.address
                });
            },

            // 地图初始化
            handler ({BMap, map}) {
                this.mapOpen()
                this.BMap = BMap
                this.map = map
                this.loadingMap = true
                var geolocation = new BMap.Geolocation()
                this.geoc = new BMap.Geocoder() // 地址解析对象
                var $this = this
                // 调用百度地图api 中的获取当前位置接口
                geolocation.getCurrentPosition(function (r) {
                    let myGeo = new BMap.Geocoder()
                    myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function (result) {
                        if (result) {
                            $this.loadingMap = false
                            // console.log(result)
                            $this.$set($this, 'pointLngLat', {lng: result.point.lng, lat: result.point.lat})
                            map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放,默认关闭
                            $this.addPoint({BMap, map})
                        }
                    })
                })
            },
// 添加点位
            addPoint ({BMap, map}) {
                map.clearOverlays()
                var point = new BMap.Point(this.pointLngLat.lng, this.pointLngLat.lat)
                let zoom = map.getZoom()
                // console.log('zoom:' + zoom)
                setTimeout(() => {
                    map.centerAndZoom(point, zoom)
                }, 0)
                var marker = new BMap.Marker(point) // 创建标注
                map.addOverlay(marker) // 将标注添加到地图中
            },
            // 点击地图
            getClickInfo (e) {
                // console.log(e)
                this.center = {lng: e.point.lng, lat: e.point.lat}
                this.pointLngLat = {lng: e.point.lng, lat: e.point.lat}
                // 获取点位信息
                let $this = this
                this.geoc.getLocation(e.point, function (rs) {
                    var addComp = rs.addressComponents
                    $this.pointAddress = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber
                    // console.log($this.pointAddress)
                })
            },
            async checkedAddress(){
                const confirmResult = await this.$confirm('该操作将把您最后一次鼠标点击的点当作隐患地址，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => err)
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消')
                }
                this.address = this.pointAddress

                // 将百度地图经纬度转为腾讯地图进行存储
                var lng = this.pointLngLat.lng
                var lat = this.pointLngLat.lat
                // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                // let x = lng - 0.0065;
                // let y = lat - 0.006;
                // let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                // let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                // let lngs = z * Math.cos(theta);
                // let lats = z * Math.sin(theta);
                this.dgeForm.dgeLng = lng
                this.dgeForm.dgeLat = lat                // console.log(this.pointLngLat,1)
                this.closeMap()
                // console.log(this.dgeForm)
            },
            // 地图相关
            mapOpen () {
                this.top = this.getScrollTop()
                if (this.top) {
                    this.setScrollTop(0)
                }
            },
// 关闭地图后调用
            closeMap () {
                this.setScrollTop(this.top)
                this.top = 0
                this.showMap = false
            },
            getScrollTop () {
                let scrollTop = 0
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop
                } else if (document.body) {
                    scrollTop = document.body.scrollTop
                }
                return scrollTop
            },
            setScrollTop (top) {
                if (!isNaN(top)) {
                    if (document.documentElement && document.documentElement.scrollTop !== undefined) {
                        document.documentElement.scrollTop = top
                    } else if (document.body) {
                        document.body.scrollTop = top
                    }
                }
            },
            handlerBMap({ BMap, map }) {
                this.BMap = BMap
                this.map = map
                if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
                    this.mapCenter.lng = this.mapLocation.coordinate.lng
                    this.mapCenter.lat = this.mapLocation.coordinate.lat
                    // this.mapZoom = 15
                    map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
                } else {
                    this.mapCenter.lng = 113.271429
                    this.mapCenter.lat = 23.135336
                    // this.mapZoom = 10
                }
            },
            querySearch(queryString, cb) {
                var that = this
                var myGeo = new this.BMap.Geocoder()
                myGeo.getPoint(queryString, function(point) {
                    if (point) {
                        that.mapLocation.coordinate = point
                        // that.makerCenter(point)
                    } else {
                        that.mapLocation.coordinate = null
                    }
                }, this.locationCity)
                var options = {
                    onSearchComplete: function(results) {
                        if (local.getStatus() === 0) {
                            // 判断状态是否正确
                            var s = []
                            for (var i = 0; i < results.getCurrentNumPois(); i++) {
                                var x = results.getPoi(i)
                                var item = { value: x.address + x.title, point: x.point }
                                s.push(item)
                                cb(s)
                            }
                        } else {
                            cb()
                        }
                    }
                }
                var local = new this.BMap.LocalSearch(this.map, options)
                local.search(queryString)
            },
            handleSelect1(item) {
                var { point } = item
                this.mapLocation.coordinate = point
                // 将百度地图经纬度转换为腾讯地图经纬度
                var lng = point.lng
                var lat = point.lat
                // let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
                // let x = lng - 0.0065;
                // let y = lat - 0.006;
                // let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
                // let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
                // let lngs = z * Math.cos(theta);
                // let lats = z * Math.sin(theta);
                this.dgeForm.dgeLng = lng
                this.dgeForm.dgeLat = lat
                // console.log(this.dgeForm)
                // this.makerCenter(point)
            },
        },
        computed: {
            pagetestlist: function () {
                var search = this.queryInfo.searchInfo
                if (search) {
                    return this.AllDgelist.filter(function (data) {
                        return Object.keys(data).some(function (key) {
                            return String(data[key]).toLowerCase().indexOf(search) > -1
                        })
                    })
                }
                return this.AllDgelist.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
            }
        }
    }
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .white_btn{
        letter-spacing: 2px;
        color: #009199;
    }
    .white_btn:hover,.white_btn:focus{
        background: #00B6C0;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .icon_add{
        width: 20px;
        height: 20px;
        padding-right: 2px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    /*标签一般*/
    .normal{
        background: #FF9900;
        color: white;
        border-radius: 2px;
        border: 1px solid #FF9990;
    }
    /*标签重要*/
    .important{
        background: #D8262A;
        color: white;
        border-radius: 2px;
        border: 1px solid #D82620;
    }
    /*标签严重*/
    .serious{
        background: #8F2139;
        color: white;
        border-radius: 2px;
        border: 1px solid #8F2100;
    }

    /deep/.el-checkbox__inner:hover {
        border-color: #009199;
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: white;
    }
    /deep/.el-checkbox__inner{
        /*缩放*/
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: #009199;
    }
    /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    /deep/.el-form-item__label {
        color: #99a9bf;
        /*margin-left: 40px;*/
    }
    /deep/.el-dropdown{
        cursor: pointer;
    }
</style>

<style lang="less" scoped>
    .el-button+.el-button{
        margin-left: 0px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #009199;
        border-color: #009199;
    }
    .el-checkbox__inner.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    .el-table{
        margin-top: 44px;
    }
    /*展开行css*/
    .XC_pic{
        width: 90px ;
        height: 90px;
    }
    .img_pad{
        margin: 30px 0;
    }
</style>
