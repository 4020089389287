<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>部门管理</el-breadcrumb-item>
            <el-breadcrumb-item v-if="this.role === 'admin'" :to="{ path : '/FindAlluser'}">所有用户</el-breadcrumb-item>
            <el-breadcrumb-item v-if="this.role === 'admin'" :to="{ path : '/Manage'}">员工管理</el-breadcrumb-item>
            <el-breadcrumb-item ><span class="hight">部门管理</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row type="flex" align="middle">
                <el-col :span="2">
                    <el-tag>
                        查询部门
                    </el-tag>
                </el-col>
                <el-col :span="4">
                    <el-dropdown @command="selectDept">
                      <el-button style="background-color: white">
                        {{this.clickDept}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="allDept">所有部门</el-dropdown-item>
                            <el-dropdown-item command="allBranch">所有一级学院</el-dropdown-item>
                            <el-dropdown-item command="masterDept">所有二级学院</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col :span="10">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getAllPartlist">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="3" :offset="5" v-if="this.role === 'admin'">
                    <el-button class="addcount" @click="addDialogVisible = true">
                        <img src="../../assets/img/btns/add.svg" class="icon_add" alt="">
                        创建部门
                    </el-button>
                </el-col>
            </el-row>
            <br>
            <el-table
                    :header-cell-style="{color: '#000000E6'}"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    :data="pagetestlist" :stripe="true">
                <el-table-column align="center" type="index" label="#"></el-table-column>
                <el-table-column align="center" prop="deptName" label="部门名称">
                    <template slot-scope="scoped">
                        <el-tag v-if="scoped.row.deptName">
                            {{scoped.row.deptName}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="deptInfo" label="部门信息"></el-table-column>
                <el-table-column align="center" prop="deptLocation" label="地址">
                    <template slot-scope="scoped">
                        <i class="el-icon-position"></i>
                        {{scoped.row.deptLocation}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="电话">
                    <template slot-scope="scoped">
                        <i class="el-icon-phone"></i>
                        {{scoped.row.deptPhone}}
                    </template>
                </el-table-column>
                <el-table-column v-if="this.role === 'admin'" align="center" label="操作"  fixed="right">
                    <template slot-scope="scope">
                        <!--              修改信息按钮-->
                        <el-tooltip effect="dark" content="部门信息" placement="top">
                            <el-button type="text" style="color: #009199" icon="el-icon-edit" size="mini" :enterable="false"  @click="showEditDialog(scope.row)">
                                编辑
                            </el-button>
                        </el-tooltip>
                        <!--              删除按钮-->
                        <el-tooltip effect="dark" content="删除账号" placement="bottom">
                            <el-button style="color: red " icon="el-icon-delete" type="text" size="mini" :enterable="false" @click="removePart(scope.row.deptId)">
                                删除
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>
        <!--    创建部门的对话框-->
        <el-dialog
                title="创建部门"
                :visible.sync="addDialogVisible"
                width="47.9%" @close="addDialogClosed" class="role-mask">
            <!--      内容主体区-->
            <el-form :model="addForm" status-icon ref="addFormRef" :rules="deptRules" label-width="120px">
                <el-form-item label="部门名称" prop="deptName">
                    <el-input v-model="addForm.deptName" placeholder="请输入部门名称"></el-input>
                </el-form-item>
                <el-form-item label="部门信息" prop="deptInfo">
                    <el-input type="textarea" :autosize="{ minRows:6}" v-model="addForm.deptInfo" placeholder="请输入部门信息" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部门地址" prop="deptLocation" >
                    <el-input v-model="addForm.deptLocation" suffix-icon="el-icon-location" placeholder="请输入部门地址"></el-input>
                </el-form-item>
                <el-form-item label="部门电话" prop="deptPhone" placeholder="请输入部门电话">
                    <el-input v-model="addForm.deptPhone" prefix-icon="el-icon-phone-outline" ></el-input>
                </el-form-item>
                <el-form-item label="部门类型" prop="deptType" placeholder="请选择部门类型">
                    <el-select v-model="addForm.deptType" >
                        <el-option label="二级学院" value="master"></el-option>
                        <el-option label="一级学院" value="branch"></el-option>
                    </el-select>
                    <span>
                        <el-tooltip effect="dark" content="部门类型默认为(一级学院)" placement="right-end">
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                    </span>
                </el-form-item>
            </el-form>
            <!--              底部按钮区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                     <el-col :span="4" :offset="5" >
                        <el-button @click="resetAdd">重 置</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="addcount" @click="addPart">创 建</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>
        <!--        编辑部门信息-->
        <el-dialog
                title="修改部门信息"
                :visible.sync="DeptDialogVisible"
                width="47.9%" @close="DeptDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="DeptForm" status-icon :rules="deptRules" ref="DeptFormRef" label-width="100px">
                <el-form-item label="部门编号">
                    <el-input v-model="DeptForm.deptId" disabled></el-input>
                </el-form-item>
                <el-form-item label="部门名称" v-model="DeptForm.deptName" prop="deptName">
                    <el-input v-model="DeptForm.deptName" placeholder="请输入部门名称"></el-input>
                </el-form-item>
                <el-form-item label="部门信息" prop="deptInfo">
                    <el-input type="textarea" :autosize="{ minRows:6}" v-model="DeptForm.deptInfo" placeholder="请输入部门信息" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部门地址" prop="deptLocation" >
                    <el-input v-model="DeptForm.deptLocation" suffix-icon="el-icon-location" placeholder="请输入部门地址"></el-input>
                </el-form-item>
                <el-form-item label="部门电话" prop="deptPhone" placeholder="请输入部门电话">
                    <el-input v-model="DeptForm.deptPhone" prefix-icon="el-icon-phone-outline" ></el-input>
                </el-form-item>
                <el-form-item label="部门类型" prop="deptType" placeholder="请选择部门类型">
                    <el-select v-model="DeptForm.deptType" >
                        <el-option label="二级学院" value="master"></el-option>
                        <el-option label="一级学院" value="branch"></el-option>
                    </el-select>
                    <span>
                        <el-tooltip effect="dark" content="部门类型默认为(一级学院)" placement="right-end">
                          <i class="el-icon-info"></i>
                        </el-tooltip>
                    </span>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="10">
                        <el-button class="addcount" @click="editDeptInfos">确定修改</el-button>
                    </el-col>
                </el-row>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "AddPart",
    data () {
        var checkPhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入联系人电话'));
            } else {
                let regPone = null;
                let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
                let tel = /^(0[0-9]{2,3}-)([2-9][0-9]{4,7})+(-[0-9]{1,4})?$/; //座机
                if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
                    regPone = tel;
                } else {
                    regPone = mobile;
                }
                if (!regPone.test(value)) {
                    return callback(
                        new Error("请填写正确的部门电话(座机格式'区号-座机号码')")
                    );
                }
                callback();
            }
        };
        return{
            loading: true,
            role: localStorage.getItem('role'),
            // 创建部门表单信息
            addForm:{
                deptName: '',
                deptInfo: '',
                deptLocation: '',
                deptPhone: '',
                deptType: 'branch'
            },
            addDialogVisible: false,
            deptRules: {
                deptPhone: {required:true,validator: checkPhone, trigger: 'blur'},
                deptName: { required:true,message:'部门名称不能为空',trigger:'blur'},
                deptInfo: { required:true,message:'部门信息不能为空',trigger:'blur'},
                deptLocation: { required:true,message:'部门地址不能为空',trigger:'blur'},
            },
            AllDeptList:[],
            queryInfo: {
                // 模糊搜索
                searchInfo: '',
                // 当前页数
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            // 修改部门信息
            DeptDialogVisible: false,
            DeptForm: {
                deptId: '',
                deptName: '',
                deptInfo: '',
                deptLocation: '',
                deptPhone: '',
                deptType: ''
            },
            DeptFormRules: {
                empName: { required:true,message:'员工名称不能为空',trigger:'blur'},
                empPhone: { required:true,validator:checkPhone,trigger:'blur'}
            },
            // 选中部门类型
            clickDept: '选择部门类型'
        }
    },
    created() {
        this.getAllPartlist()
    },
    methods: {
        async getAllPartlist(){
            const { data: res } = await this.$http.get('/dept/allDept')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.AllDeptList = res.data
            this.total = res.data.length
            if(this.AllDeptList.length === res.data.length){
                this.loading = false
            }
        },
        // 监听 pagesize 改变的事件
        handleSizeChange (newSize) {
            this.queryInfo.pagesize = newSize
            this.getAllPartlist()
        },
        // 监听页码值改变的事件
        handleCurrentChange (newPage) {
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getAllPartlist()
        },
        // 点击选择部门按钮
        async selectDept(deptType){
            // console.log(deptType)
            if (deptType === 'allDept') {
                this.clickDept = '所有部门'
            }
            if (deptType === 'allBranch') {
                this.clickDept = '所有一级学院'
            }
            if (deptType === 'masterDept') {
                this.clickDept = '所有二级学院'
            }
            const { data: res } = await this.$http.get('/dept/'+ deptType)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            // this.$message.success('请求查询成功!')
            var list = res.data
            if(list.length){
                this.AllDeptList = res.data
                this.total = res.data.length
            }
            else {
                var arr  = [{"deptId":list.deptId,"deptName":list.deptName,
                    "deptInfo":list.deptInfo,"deptLocation":list.deptLocation,
                    "deptNum":list.deptNum,"deptPhone":list.deptPhone,
                    "deptHead":list.deptHead,"deptType":list.deptType}]
                this.AllDeptList = arr
                this.total = arr.length
            }
        },
        // 监听添加部门对话框的关闭事件
        addDialogClosed () {
            this.$refs.addFormRef.resetFields()
        },
        resetAdd () {
            this.$refs.addFormRef.resetFields()
        },
        addPart () {
            this.$refs.addFormRef.validate(async valid => {
                if (!valid) return
                // 调用接口进行用户添加
                const { data: res } = await this.$http.post('/dept/create/', this.addForm)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('创建部门成功!')
                // console.log(res)
                // 隐藏添加用户的对话框
                this.addDialogVisible = false
                this.getAllPartlist()
            })
        },
        DeptDialogClosed () {
            this.$refs.DeptFormRef.resetFields()
        },
    //    展示修改部门信息对话框
        showEditDialog(list){
            this.DeptDialogVisible = true
            this.DeptForm = list
            // console.log(list)
        },
        async editDeptInfos() {
            // console.log(this.DeptForm)
            const {data: res} = await this.$http.put('/dept/update', this.DeptForm)
            // console.log(res)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.DeptDialogVisible = false
            this.$message.success('修改部门信息成功!')
            this.getAllPartlist()
        },
    //    删除部门
        async removePart(deptId){
            // console.log(deptId)
            const confirmResult = await this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 若用户确认删除，则返回值为字符串 confirm
            // 若取消删除，返回 cancel
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消删除')
            }
            // console.log('确认删除')
            const { data: res } = await this.$http.delete('/dept/delete/' + deptId)
            if (res.status !== 200) {
                return this.$message.error('删除部门失败！')
            }
            this.$message.success('删除部门成功!')
            this.getAllPartlist()
        }
    },
    computed: {
        pagetestlist: function () {
            var search = this.queryInfo.searchInfo
            if (search) {
                return this.AllDeptList.filter(function (data) {
                    return Object.keys(data).some(function (key) {
                        return String(data[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return this.AllDeptList.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
        }
    }
}
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }

    .icon_add{
        width: 24px;
        height: 24px;
        padding-right: 4px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #009199;
        font-size: 20px;
    }
</style>
