<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>账号管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path : '/FindAlluser'}">所有用户</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">员工管理</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row type="flex" align="middle">
                <el-col :span="2">
                    <el-tag>
                        所属部门
                    </el-tag>
                </el-col>
                <el-col  :span="4">
                    <el-dropdown @command="selectDept">
                      <el-button style="background-color: white">
                        {{this.deptName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="-1">所有部门</el-dropdown-item>
                            <el-dropdown-item v-for="(item,index) in AllDeptList"
                                              v-text="item.deptName"
                                              :command="item.deptId"
                                              :key="index"
                            ></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col :span="10">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="3" :offset="5">
                    <el-button class="addcount" @click="addDialogVisible = true">
                        <img src="../assets/img/btns/add.svg" class="icon_add" alt="">
                        添加账号
                    </el-button>
                </el-col>
            </el-row>
            <br>

            <el-table
                    :header-cell-style="{color: '#000000E6'}"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    :data="pagetestlist" :stripe="true">
                <el-table-column type="index" label="#" align="center" width="40px"></el-table-column>
                <el-table-column align="center" label="名称" >
                    <template slot-scope="scope">
                        <el-tooltip  effect="light" content="设为部门管理员" placement="bottom">
                            <span>
                                <i class="el-icon-star-off" @click="AddToAdmin(scope.row.empId,scope.row.deptId)"></i>
                            </span>
                        </el-tooltip>
                        <span style="margin-left: 5px">{{scope.row.empName}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="deptName" label="所属部门">
                    <template slot-scope="scoped">
                        <el-tag v-if="scoped.row.deptName">
                            {{scoped.row.deptName}}
                        </el-tag>
                    </template>

                </el-table-column>
                <el-table-column  align="center" prop="empStation" label="岗位">
                    <template slot-scope="scope">
                        <div v-if="scope.row.empStation" >
                            <i v-if="scope.row.empStation === '管理员'" class="el-icon-s-custom" style="padding-right: 6px"></i>
                            <i v-else-if="scope.row.empStation === '主管'" class="el-icon-user-solid" style="padding-right: 6px"></i>
                            <i v-else class="el-icon-user" style="padding-right: 6px"></i>
                            <el-tag type="success">
                                {{scope.row.empStation}}
                            </el-tag>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column align="center" prop="empPhone" label="电话"></el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <!--              修改信息按钮-->
                        <el-tooltip effect="dark" content="个人信息" placement="top">
                            <el-button type="text" style="color: #009199" icon="el-icon-edit" size="mini" :enterable="false"  @click="showEditDialog(scope.row)">
                                编辑
                            </el-button>
                        </el-tooltip>
                        <!--              修改信息按钮-->
                        <el-tooltip effect="dark" content="部门信息" placement="bottom">
                            <el-button type="text" icon="el-icon-edit-outline" size="mini" :enterable="false"  @click="showDeptDialog(scope.row.empId)">
                                修改
                            </el-button>
                        </el-tooltip>
                        <!--              删除按钮-->
<!--                        <el-tooltip effect="dark" content="删除账号" placement="top">-->
<!--                            <el-button style="color: red " type="text" size="mini" :enterable="false">-->
<!--                                删除-->
<!--                            </el-button>-->
<!--                        </el-tooltip>-->
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>

        <!--    添加单个用户的对话框-->
        <el-dialog
                title="添加人员"
                :visible.sync="addDialogVisible"
                width="47.9%" @close="addDialogClosed" class="role-mask">
            <!--      内容主体区-->
            <el-collapse>
                <el-collapse-item>
                    <template slot="title">
                        <i class="header-icon el-icon-warning"></i>
                        &nbsp; 单击可查看用户权限描述表。
                    </template>
                    <el-table
                            stripe
                            :data="RoleList"
                            style="width: 100%">
                        <el-table-column
                                prop="perm"
                                label="权限"
                                align="center"
                        >
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="info"
                                label="描述">
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
            <el-form :model="addForm" status-icon :rules="rules" ref="addFormRef" label-width="120px">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="addForm.username" placeholder="请输入用户名(手机号)"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="addForm.password" placeholder="请输入密码" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="用户权限" prop="userPerms" placeholder="请选择用户权限">
                    <el-select v-model="addForm.userPerms" >
                        <el-option label="普通" value="normal"></el-option>
                        <el-option label="管理者" value="manage"></el-option>
                        <el-option label="超级管理员" value="super"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="员工微信" prop="empWeixin">
                    <el-input v-model="addForm.empWeixin" placeholder="请输入员工微信" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所属供电所编号" prop="deptId">
                    <el-select v-model="addForm.deptId" placeholder="请选择所属供电所编号">
                        <el-option
                                v-for="item in AllDeptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="员工岗位" prop="empStation">
                    <el-input v-model="addForm.empStation" placeholder="请输入员工岗位（选填）" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--              底部按钮区域-->
            <span slot="footer" class="dialog-footer">
                <el-row :gutter="20">
                    <el-col :span="6" :offset="4">
                        <el-button @click="resetAdd">重 置</el-button>
                    </el-col>
                    <el-col :span="4" :offset="4">
                        <el-button class="addcount" @click="addUser">添 加</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>

        <!--        编辑员工个人信息-->
        <el-dialog
                title="修改员工个人信息"
                :visible.sync="EditDialogVisible"
                width="47.9%" @close="DialogClosed">
            <!--      内容主体区域-->
            <el-form :model="EditForm" status-icon :rules="EditFormRules" ref="EditFormRef" label-width="100px">
                <el-form-item label="员工编号" >
                    <el-input v-model="EditForm.empId" disabled></el-input>
                </el-form-item>
                <el-form-item label="员工名称" prop="empName">
                    <el-input v-model="EditForm.empName" placeholder="请设置员工新名称"></el-input>
                </el-form-item>
                <el-form-item label="员工电话" prop="empPhone">
                    <el-input placeholder="请设置员工新电话" v-model="EditForm.empPhone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="员工微信" prop="empWeixin">
                    <el-input autocomplete="off" placeholder="请设置员工微信" v-model="EditForm.empWeixin"></el-input>
                </el-form-item>
                <el-form-item label="员工岗位" prop="empStation" placeholder="请选择员工岗位">
                    <el-select v-model="EditForm.empStation" >
                        <el-option label="主管" value="主管"></el-option>
                        <el-option label="普通员工" value="普通员工"></el-option>
                        <el-option label="管理员" value="管理员"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="员工状态" required prop="empStatus">
                    <el-switch
                            v-model="EditForm.empStatus"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-value="enable"
                            inactive-value="disable"
                            active-text="可用"
                            inactive-text="不可用"
                    >
                    </el-switch>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
<!--          <el-button @click="resetForm">重置修改</el-button>-->
                <el-row>
                    <el-col :span="4" :offset="10">
                         <el-button class="addcount" @click="editInfos">确定修改</el-button>
                    </el-col>
                </el-row>
        </span>
        </el-dialog>
        <!--        编辑员工部门信息-->
        <el-dialog
                title="修改员工部门信息"
                :visible.sync="DeptDialogVisible"
                width="47.9%" @close="DeptDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="DeptForm" status-icon ref="DeptFormRef" label-width="100px">
                <el-form-item label="员工编号">
                    <el-input v-model="DeptForm.empId" disabled></el-input>
                </el-form-item>
                <el-form-item label="部门编号" prop="deptId">
                    <el-select v-model="DeptForm.deptId" >
                        <el-option
                                v-for="item in this.AllDeptList"
                                :key="item.deptId"
                                :label="item.deptName"
                                :value="item.deptId"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="10">
                        <el-button class="addcount" @click="editDeptInfos">确定修改</el-button>
                    </el-col>
                </el-row>
        </span>
        </el-dialog>
    </div>
</template>

<script>
var md5 = require('md5')
export default {
    name: "Manage",
    data(){
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('用户名(手机号)不能为空'));
            } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
                // console.log(reg.test(value));
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的用户名(手机号)'));
                }
            }
        }

        var checkPass = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('密码不能为空'));
            } else {
                const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
                // console.log(reg.test(value));
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('密码须包含数字、字母两种元素，且密码位数为6-18位'));
                }
            }
        }
        return{
            addDialogVisible: false,
            addForm: {
                username: '',
                password: '',
                name: '',
                userRole: '',
                userPerms: '',
                empWeixin: '',
                deptId: '',
                empStation: ''
            },
            flag1: true,
            rules: {
                username: {required:true,validator: checkPhone, trigger: 'blur'},
                password: {validator:checkPass, required:true,trigger:'blur'},
                name: { required:true,message:'部门信息不能为空',trigger:'blur'},
                // userRoles: { required:true,trigger:'blur'},
                userPerms: { required:true,message:'用户权限不能为空',trigger:'blur'}
            },
            RoleList:[{
                perm: '普通',
                info: '所里员工'
            }, {
                perm: '管理者',
                info: '局里员工'
            }, {
                perm: '超级管理员',
                info: '超级管理员'
            }],
            //
            flag: false,
            queryInfo: {
                // 模糊搜索
                searchInfo: '',
                // 当前页数
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            // 所有员工信息
            AllUserlist: [],
            EditDialogVisible: false,
            EditForm: {
                empId: '',
                empName: '',
                empPhone: '',
                empWeixin: '',
                empStation: '',
                empStatus: 'enable',
            },
            EditFormRules: {
                empName: { required:true,message:'员工名称不能为空',trigger:'blur'},
                empPhone: { required:true,validator:checkPhone,trigger:'blur'}
            },
        //    所有部门信息
            AllDeptList: [],
            deptName: '选择部门',
            // 现在选中的部门信息
            NowDeptList: [],
            // 修改员工部门信息
            DeptDialogVisible: false,
            DeptForm: {
                empId: '',
                deptId: '',
            },
            loading: true,
            role: localStorage.getItem('role')
        }
    },
    created() {
        this.check()
        this.getUserList()
        this.getDeptList()
    },
    methods:{
        check(){
            if (localStorage.getItem('role') !== 'admin'){
                this.$message.error('你没有权限!')
                this.$router.push('/Addpart')
            }
        },
// 监听添加单个用户对话框的关闭事件
        addDialogClosed () {
            this.$refs.addFormRef.resetFields()
        },
        resetAdd () {
            this.$refs.addFormRef.resetFields()
        },
        addUser () {
            if (this.addForm.userPerms === 'super'){
                this.addForm.userRole = 'admin'
            }
            else if(this.addForm.userPerms === 'manage'){
                this.addForm.userRole = 'manager'
            }
            else {
                this.addForm.userRole = 'employee'
            }
            // console.log(this.addForm.userRole)
            this.$refs.addFormRef.validate(async valid => {

                var pass = this.addForm.password
                if (pass) {
                    this.addForm.password = md5(pass).toUpperCase()
                }
                // console.log(this.addForm.password)
                if (!valid) return
                // 调用接口进行用户添加
                const {data: res} = await this.$http.post('/user/create/', this.addForm)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('添加用户成功!')
                this.getUserList()
                // console.log(res)
                // 隐藏添加用户的对话框
                this.addDialogVisible = false
            })
        },
        // 获取所有部门信息
        async getDeptList () {
            const { data: res } = await this.$http.get('/dept/allDept')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.AllDeptList = res.data
        },
        // 点击选择部门按钮
        selectDept(deptId){
            // console.log(deptId)
            if (deptId == -1) {
                this.getUserList()
                this.deptName= '所有部门'
            }
            else {
                this.getOnedept(deptId)
            }

        },
        // 获取所有员工信息列表
        async getUserList () {
            var role = localStorage.getItem('role')
            // console.log(role)
            if (role === 'admin'){
                const { data: res } = await this.$http.get('/emp/allEmp')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.AllUserlist = res.data
                this.total = res.data.length
                if (this.AllUserlist.length === res.data.length){
                    this.loading = false
                }
            }
            else {
                const { data: res } = await this.$http.get('/emp/showByDept/' + localStorage.getItem('deptId'))
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.AllUserlist = res.data
                this.total = res.data.length
                if (this.AllUserlist.length === res.data.length){
                    this.loading = false
                }
            }
        },
        // 监听 pagesize 改变的事件
        handleSizeChange (newSize) {
            this.queryInfo.pagesize = newSize
            this.getUserList()
        },
        // 监听页码值改变的事件
        handleCurrentChange (newPage) {
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getUserList()
        },
        // 选中其中一个部门
        async getOnedept(deptId){
            const {data: res } = await this.$http.get('/emp/showByDept/' + deptId)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (res.data[0]){
                this.deptName = res.data[0].deptName
                this.AllUserlist = res.data
                this.total = res.data.length
            }
            else {
                this.$message.warning('暂无属于该部门的人员')
                this.dgeTypeName = '选择部门'
            }

        },
        // 打开编辑修改员工部门的对话框
        showDeptDialog(empId) {
            this.DeptDialogVisible = true
            this.DeptForm.empId = empId
        },
        DeptDialogClosed () {
            this.$refs.DeptFormRef.resetFields()
        },
        // 修改员工部门信息
        async editDeptInfos(){
            // console.log(this.DeptForm.deptId)
            const {data: res} = await this.$http.put('/emp/updateDept/', {
                empId: this.DeptForm.empId, deptId: this.DeptForm.deptId
            })
            // console.log(res)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.DeptDialogVisible = false
            this.$message.success('修改员工部门信息成功!')
            this.getUserList()
        },
        // 获取员工信息
        async showEditDialog(list) {
            this.EditDialogVisible = true
            this.EditForm = list
        },
        DialogClosed () {
            this.$refs.EditFormRef.resetFields()
        },

        // 点击按钮，修改用户info
        async editInfos () {
            this.$refs.EditFormRef.validate(async valid => {
                // console.log(valid)
                if (!valid) return
                // console.log(this.EditForm)
                const {data: res} = await this.$http.put('/emp/update', this.EditForm)
                // console.log(res)
                if (res.status !== 200) {
                    // return this.$message.error('更新员工信息失败!')
                    return this.$message.error(res.msg)
                }
                this.$message.success('更新员工信息成功！')
                this.EditDialogVisible = false
                this.getUserList()
            })
        },
        //    将某一员工设置为部门管理员
        AddToAdmin(empId,deptId){
            // console.log(empId, deptId)
            if (deptId == null){
                this.$message.error('该员工未属于某部门，请先加入部门!');
            }
            else{
                this.$confirm('此操作将把该员工设置为他所在部门的管理员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.put('/dept/updateHead/', {
                        deptId: deptId, deptHead: empId
                    }).then( (res) => {
                        // console.log(res)
                        if (res.status !== 200) {
                            this.$message.error(res.status.msg)
                        }
                        else {
                            this.$message({
                                message: '设置成功!',
                                type: 'success'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消设置'
                    });
                });
            }
        }
    },
    computed: {
        pagetestlist: function () {
            var search = this.queryInfo.searchInfo
            if (search) {
                return this.AllUserlist.filter(function (data) {
                    return Object.keys(data).some(function (key) {
                        return String(data[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return this.AllUserlist.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
        }
    }
}
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }

    .icon_add{
        width: 26px;
        height: 26px;
        padding-right: 4px;
        vertical-align:middle;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #009199;
        font-size: 20px;
    }
     .hight{
        color: #009199 !important;
         font-weight: bold;
    }
</style>
