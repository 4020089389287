import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home";
import Login from "../views/Login";
import FindAlluser from "../components/admin/FindAlluser";
import AddPart from "../components/admin/AddPart";
import Static from "../components/Static";
import Maps from  "../components/Maps"
import dgeInfo from "../components/dgeInfo";
import checkDge from "../components/checkDge";
import Manage from "../components/Manage";
import chkInfo from "../components/chkInfo";
import AddDge from "../components/AddDge";
import dgeInfos from "../components/dgeInfos";
import chkInfos from "../components/chkInfos";
import chkStatic from "../components/chkStatic";
import dgeTypeInfo from "../components/admin/dgeTypeInfo";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Static'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/Static',
    children: [
      { path: '/Static', component: Static },
      { path: '/Maps', component: Maps },
      { path: '/dgeInfo', component: dgeInfo},
      { path: '/checkDge', component: checkDge},
      { path: '/Manage', component: Manage},
      { path: '/FindAlluser',component: FindAlluser},
      { path: '/AddPart', component: AddPart},
      { path: '/chkInfo', component: chkInfo},
      { path: '/AddDge', component: AddDge},
      { path: '/dgeInfos', component: dgeInfos},
      { path: '/chkInfos', component: chkInfos},
      { path: '/chkStatic', component: chkStatic},
      { path: '/dgeTypeInfo', component: dgeTypeInfo}
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫

router.beforeEach((to, from, next) => {
  const strtoken = localStorage.getItem('token')
  if (strtoken) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
