<template>
    <div class="bg">
        <div class="center">
            <div class="title">
                <img class="ligong" src="../assets/img/ligong_title.png" alt="">
            </div>
            <div class="login_form">
              <el-form :model="loginForm"
                       :rules="loginFormRules"
                       status-icon
                     ref="loginFormRef"
                     label-position="left"
                     label-width="0px"
                     class="login-page">
                <div class="login_title">账号登录
                    <div class="tiao"></div>
                </div>
                <el-form-item class="form_item" prop="username" required>
                    <el-input class="input_first"
                            v-model="loginForm.username"
                            auto-complete="off"
                            placeholder="请输入用户名"
                    > <i slot="prefix">
                        <img src="../assets/img/login/username.svg" class="icons" alt="">
                    </i>
                    </el-input>
                </el-form-item>
                <el-form-item class="form_item1" prop="password" required>
                    <el-input type="password" class="input_first"
                              v-model="loginForm.password"
                              auto-complete="off"
                              placeholder="请输入密码"
                    >
                        <i slot="prefix">
                            <img src="../assets/img/login/passwd.svg" class="icons" alt="">
                        </i>
                    </el-input>
                </el-form-item>
                <!--        验证码-->
                <el-form-item class="form_item1" prop="code">
                    <el-input @keyup.enter.native="login" class="input_first" type="text" v-model="loginForm.code" placeholder=" - - - -">
                        <template slot="suffix" >
                            <img class="yanzheng" @click="changeImgCode" :src="imgCode" alt="">
                        </template>
                        <i slot="prefix">
                            <img src="../assets/img/login/code.svg" class="icons" alt="">
                        </i>
                    </el-input>
                </el-form-item>
                <el-form-item class="form_item1" style="width:100%;">
                    <el-button class="login_btn" @click="login">登 录</el-button>
                </el-form-item>
            </el-form>
            </div>
            <div class="foot">
                © 1998 - 2021 宁波 ligong Inc. All Rights Reserved
            </div>
       </div>
    </div>
</template>
<script src="../js/md5.js"></script>
<script>
var md5 = require('md5')

export default {
    data(){
        return {
            loginFormRules: {
              username: [
                  {required:true, message: '请输入登录账号', trigger: 'blur'},
                  {required:true, message: '请输入登录账号', trigger: 'change'}],
              password: [
                  {required:true, message: '请输入密码', trigger: 'blur'},
                  {required:true, message: '请输入密码', trigger: 'change'}],
            },
            logining: false,
            loginForm: {
                username: '',
                password: '',
                // password: 'E10ADC3949BA59ABBE56E057F20F883E',
                code: ''
            },
            imgCode: 'https://www.heilama.com:8383/user/kaptcha',
        }
    },
    created() {
    },
    methods: {
        // 重置
        resetLoginForm () {
            // console.log(this);
            this.$refs.loginFormRef.resetFields()
        },
        login () {
            this.$refs.loginFormRef.validate(async valid => {
                if (!valid) return;
                var pass = this.loginForm.password
                this.loginForm.password = md5(pass).toUpperCase()
                // console.log(this.loginForm.password)
                const { data: res } = await this.$http.post('/user/login', this.loginForm)
                console.log(res)
                if (res.status !== 200){
                    this.resetLoginForm ()
                    return this.$message.error(res.msg)
                }
                if (res.data.userStatus !== 'enable') return this.$message.error('没有权限，若有疑问请联系管理员!')
                this.$message.success('登录成功！')
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('username', res.data.username)
                localStorage.setItem('role', res.data.userRole)
                localStorage.setItem('empId',res.data.userRole)
                if (res.data.userRole  !== 'admin'){
                    this.getUserInfo(res.data.empId)
                }
                this.$router.push('/home')
            })
            this.changeImgCode()
        },
        async getUserInfo(empId){
            const { data: res } = await this.$http.get('/emp/showInfo/'+ empId)
            console.log(res)
            if (res.status !== 200){
                return this.$message.error(res.msg)
            }
            localStorage.setItem('deptId',res.data.deptId)
        },
        changeImgCode() {
            var num=Math.ceil(Math.random()*10);//生成一个随机数（防止缓存）
            this.imgCode = "https://www.heilama.com:8383/user/kaptcha?" + num;
        }
    }
};
</script>


<style scoped>
    .bg{
        background: url(../assets/img/background.png);
        /*background-color: #F7F7F7;*/
        background-size: 100% 100%;
        height: 100%;
        position: fixed;
        width: 100%;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }
    /*.login-container {*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/
    .center{
        margin: 0 auto;
        text-align: center;
        /*transform: scale(0.6);*/
        /*width: 420px;*/
    }
    .login_form{
        background: rgba(0,0,0,0.30);
        border: 1px solid #00E6FF;
        box-shadow: 0 0 10px 0 #04C9DE;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        /*border-radius: 5px;*/
        text-align: center;
        margin: auto;
        margin-top: 35px;
        width: 460px;
        height: 420px;
        /*transform: scale(0.6);*/
    }
    .form_item{
        margin: auto;
        margin-top: 39px;
    }
    .form_item1{
        margin: auto;
        margin-top: 23px;
    }
    .input_first{
        margin: auto;
        /*margin-left: 20px;*/
        height: 48px;
        width: 360px !important;
        background: #FFFFFF;
        border-radius: 30px;
        /*transform: scale(1.01);*/
    }
    /deep/ .el-input__inner{
        magin: auto;
        /*margin-top: 39px;*/
        text-indent: 32px;
        height: 48px;
        width: 360px;
        background: #FFFFFF;
        border-radius: 30px;
        /*transform: scale(1.01);*/
    }
    /deep/ .el-form-item__error{
        left: 80px;
    }
    .login_title{
        font-family: PingFangSC-Semibold;
        font-size: 28px;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin: auto;
        margin-top: 29px;

    }
    .tiao{
        height: 6px;
        width: 102px;
        margin: auto;
        margin-top: 2px;
        border-bottom: solid 4px #00B6C0;
    }
    .title{
        font-family: zihun143-zhengkuchaojihei;
        font-size: 48px;
        color: #FFFFFF;
        letter-spacing: 8px;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        text-align: center;
        margin: auto;
        margin-top: 208px;
        /*transform: scale(0.5);*/
    }
    .yanzheng{
        width: 86px;
        height: 34px;
        text-align: center;
        margin: 5px 30px;
        border: 2px solid #009199;
    }
    .foot{
        margin-top: 24px;
        font-family: SourceSansPro-Regular;
        font-size: 16px;
        color: white;
        letter-spacing: 0.4px;
    }
    .login_btn{
        background: #00B6C0;
        border-radius: 30px;
        border: 1px solid #009199;
        color: white;
        width: 100%;
        width: 360px;
        height: 48px;
        font-size: 24px;
        letter-spacing: 0px;
    }
    .icons{
        width: 22px ;
        height :22px;
        margin: 13px auto;
        margin-left: 25px;
    }
    /deep/ input::-webkit-input-placeholder {
        /*padding-left: 30px;*/
    }

    .ligong {
        width: 360px;
        height: 62px;
    }
</style>
