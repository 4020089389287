<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>账号管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path : '/Addpart'}">部门管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path : '/Manage'}">员工管理</el-breadcrumb-item>
            <el-breadcrumb-item ><span class="hight">所有用户</span> </el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!--      搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-input placeholder="请输入内容" v-model="queryInfo.searchInfo" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <el-table v-loading="loading"
                      :header-cell-style="{color: '#000000E6'}"
                      element-loading-text="拼命加载中"
                      element-loading-spinner="el-icon-loading"
                      :data="pagetestlist" :stripe="true">
                <el-table-column align="center" type="index" label="#"></el-table-column>
                <el-table-column align="center" prop="username" label="用户"></el-table-column>
<!--                <el-table-column align="center" prop="empId" label="员工编号">-->
<!--                    <template slot-scope="scope">-->
<!--                        <i class="el-icon-bank-card" style="padding-right: 6px"></i>-->
<!--                        <span style="color: #009199">{{scope.row.empId}}</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column align="center" prop="userRole" label="用户角色">
                    <template slot-scope="scope">
                        <el-tag type="success">
                            {{scope.row.userRole === 'admin' ? '超级管理员' : (scope.row.userRole === 'manager' ? '局里员工' : '所里员工')}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="userPerms" label="用户权限">
                    <template slot-scope="scope">
                        <el-tag type="warning">
                            {{scope.row.userPerms === 'super' ? '超级管理员' : (scope.row.userPerms === 'manage' ? '管理者' : '普通')}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="userStatus" label="用户状态">
                    <template slot-scope="scope">
                        <el-switch
                                value="true"
                                active-value="true"
                                :width="36"
                                v-if="scope.row.userStatus === 'enable'"
                                active-color="#13ce66">
                        </el-switch>
                        <el-switch
                                :width="36"
                                v-if="scope.row.userStatus === 'disenable'"
                                inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                        <!--              修改密码按钮-->
                        <el-tooltip effect="dark" content="修改密码" placement="top">
                            <el-button type="text" style="color: #009199" icon="el-icon-key" size="mini" :enterable="false" @click="showKeyEditDialog(scope.row.username)">
                               修改
                            </el-button>
                        </el-tooltip>
                        <!--              修改名字按钮-->
                        <el-tooltip effect="dark" content="修改用户信息" placement="bottom">
                            <el-button type="text" icon="el-icon-edit" size="mini" :enterable="false"  @click="showEditDialog(scope.row.userId, scope.row.username)">
                                编辑
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>
<!--        修改密码-->
        <el-dialog
                title="修改用户密码"
                :visible.sync="KeyDialogVisible"
                width="47.9%" @close="editDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="KeyEditForm" :rules="keyFormRules" status-icon ref="KeyEditFormRef" label-width="100px">
                <el-form-item label="用户名" required>
                    <el-input v-model="KeyEditForm.username" disabled></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newpass" required>
                    <el-input type="password"  placeholder="请设置新密码" v-model="KeyEditForm.newpass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass" required>
                    <el-input type="password"  @keyup.enter.native="editPwds" placeholder="请确认新密码" v-model="KeyEditForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="5">
                        <el-button class="addcount" @click="editPwds">确定修改</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="white_btn" @click="resetEdit">重置修改</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>
        <!--        修改用户信息-->
        <el-dialog
                title="修改用户用户"
                :visible.sync="EditDialogVisible"
                width="47.9%" @close="DialogClosed">
            <!--      内容主体区域-->
            <el-collapse>
                <el-collapse-item>
                    <template slot="title">
                        <i class="header-icon el-icon-warning"></i>
                        &nbsp; 单击可查看用户权限描述表。
                    </template>
                    <el-table
                            stripe
                            :data="RoleList"
                            style="width: 100%">
                        <el-table-column
                                prop="perm"
                                label="权限"
                                align="center"
                        >
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="info"
                                label="描述">
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
            <el-form :model="EditForm" status-icon :rules="EditFormRules" ref="EditFormRef" label-width="100px">
                <el-form-item label="用户编号">
                    <el-input v-model="EditForm.userId" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="EditForm.username" placeholder="请设置新用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="EditForm.password" disabled autocomplete="off"></el-input>
                </el-form-item>
<!--                <el-form-item label="用户身份" prop="userRoles" placeholder="请选择用户身份">-->
<!--                    <el-radio-group v-model="EditForm.userRole">-->
<!--                        <el-radio-button label="employee"></el-radio-button>-->
<!--                        <el-radio-button label="manager"></el-radio-button>-->
<!--                        <el-radio-button label="admin"></el-radio-button>-->
<!--                    </el-radio-group>-->
<!--                </el-form-item>-->
                <el-form-item label="用户权限" prop="userPerms" placeholder="请选择用户权限">
                    <el-select v-model="EditForm.userPerms" >
                        <el-option label="普通" value="normal"></el-option>
                        <el-option label="管理者" value="manage"></el-option>
                        <el-option label="超级管理员" value="super"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户状态" required prop="userStatus">
                    <el-switch
                            v-model="EditForm.userStatus"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-value="enable"
                            inactive-value="disable"
                            active-text="可用"
                            inactive-text="不可用"
                    >
                    </el-switch>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
<!--          <el-button @click="resetForm">重置修改</el-button>-->
                <el-row>
                    <el-col :span="4" :offset="10">
                        <el-button class="addcount" @click="editInfos">确定修改</el-button>
                    </el-col>
                </el-row>

        </span>
        </el-dialog>
    </div>

</template>

<script>
var md5 = require('md5')
export default {
    name: "FindAlluser",
    data () {
        // 密码校验
        var Pass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'))
            } else {
                callback()
            }
        }
        var Pass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.KeyEditForm.newpass) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }
        var checkPass = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('密码不能为空'));
            } else {
                const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
                // console.log(reg.test(value));
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('密码须包含数字、字母两种元素，且密码位数为6-18位'));
                }
            }
        }
        return {
            loading: true,
            queryInfo: {
                // 模糊搜索
                searchInfo: '',
                // 当前页数
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            AllUserlist: [],
            KeyEditForm: {
                username: '',
                newpass: '',
                checkPass: ''
            },
            keyFormRules: {
                newpass: [
                    {
                        require: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    },
                    {
                        min: 6,
                        max: 40,
                        message: '密码长度不少于6位',
                        trigger: 'blur'
                    },
                    {
                        validator:checkPass, required:true,trigger:'blur'
                    },
                    {
                        validator: Pass,
                        trigger: 'blur'
                    }
                ],
                checkPass: [
                    {
                        require: true,
                        message: '确认新密码不能为空',
                        trigger: 'blur'
                    },
                    {
                        validator: Pass2,
                        trigger: 'blur'
                    }
                ]
            },
            KeyDialogVisible: false,
            EditDialogVisible: false,
            EditForm: {
                userId: '',
                username: '',
                password: '',
                userRole: '',
                userPerms: '',
                userStatus: 'enable'
            },
            EditFormRules: {
                username: { required:true,message:'用户名不能为空',trigger:'blur'},
            },
            RoleList:[{
                perm: '普通',
                info: '所里员工'
            }, {
                perm: '管理者',
                info: '局里员工'
            }, {
                perm: '超级管理员',
                info: '超级管理员'
            }],
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        async getUserList () {
            const { data: res } = await this.$http.get('/user/allUser')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.AllUserlist = res.data
            this.total = res.data.length
            if (this.AllUserlist.length === res.data.length){
                this.loading = false
            }
        },
        // 监听 pagesize 改变的事件
        handleSizeChange (newSize) {
            this.queryInfo.pagesize = newSize
            this.getUserList()
        },
        // 监听页码值改变的事件
        handleCurrentChange (newPage) {
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getUserList()
        },
        // 重置修改密码输入框
        resetEdit () {
            this.$refs.KeyEditFormRef.resetFields()
        },
        // resetForm () {
        //     this.$refs.EditFormRef.resetFields()
        // },
        // 展示修改用户密码的对话框
        showKeyEditDialog (username) {
            this.KeyDialogVisible = true
            this.KeyEditForm.username = username
        },
        // 监听修改密码对话框的关闭事件
        editDialogClosed () {
            this.$refs.KeyEditFormRef.resetFields()
        },
        async showEditDialog(userId, username) {
            this.EditDialogVisible = true
            this.EditForm.userId = userId
            const {data: res} = await this.$http.get('/user/showInfo/'+ username)
            // console.log(res)
            this.EditForm = res.data
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            // this.$message.success('获取该用户原本信息成功！')
        },
        DialogClosed () {
            this.$refs.EditFormRef.resetFields()
        },
        // 点击按钮，修改用户密码
        editPwds () {
            this.$refs.KeyEditFormRef.validate(async valid => {
                // console.log(valid)
                var pass = this.KeyEditForm.newpass
                this.KeyEditForm.newpass = md5(pass).toUpperCase()
                if (!valid) return
                const {data: res} = await this.$http.put('/user/setPwd/', {
                    username: this.KeyEditForm.username,
                    password: this.KeyEditForm.newpass
                })
                if (res.status !== 200) {
                    return this.$message.error('更新用户密码失败!')
                }
                this.$message.success('更新用户密码成功！')
                this.KeyDialogVisible = false
                this.getUserList()
            })
        },
        // 点击按钮，修改用户info
        editInfos () {
            if (this.EditForm.userPerms === 'super'){
                this.EditForm.userRole = 'admin'
            }
            else if(this.EditForm.userPerms === 'manage'){
                this.EditForm.userRole = 'manager'
            }
            else {
                this.EditForm.userRole = 'employee'
            }
            this.$refs.EditFormRef.validate(async valid => {
                // console.log(valid)
                if (!valid) return
                const {data: res} = await this.$http.put('/user/update/', this.EditForm)
                if (res.status !== 200) {
                    return this.$message.error('更新用户信息失败!')
                }
                this.$message.success('更新用户信息成功！')
                this.EditDialogVisible = false
                this.getUserList()
            })
        }
    },
    computed: {
        pagetestlist: function () {
            var search = this.queryInfo.searchInfo
            if (search) {
                return this.AllUserlist.filter(function (data) {
                    return Object.keys(data).some(function (key) {
                        return String(data[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return this.AllUserlist.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
        }
    }
}
</script>

<style scoped>
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .white_btn{
        letter-spacing: 2px;
        color: #009199;
    }
    .white_btn:hover,.white_btn:focus{
        background: #00B6C0;
        color: ghostwhite;
        letter-spacing: 2px;
    }
</style>
