<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">巡视统计</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
        <el-row align="middle" type="flex" >
            <el-col :span="5" style="color: #009199"><span style="color: #969896">目前为:</span><b>{{title}}</b></el-col>
            <el-col  v-if="this.role == 'admin'" :span="2">
                <el-tag>
                    选择部门
                </el-tag>
            </el-col>
            <el-col v-if="this.role == 'manager'" :span="2">
                <el-tag>
                    选择供电所
                </el-tag>
            </el-col>
            <el-col v-if="this.role !== 'employee'" :span="3">
                <el-dropdown @command="selectDept">
                    <el-button style="background-color: white">
                        {{this.deptName}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in deptList"
                                          v-text="item.deptName"
                                          :command="item.deptId"
                                          :key="index"
                        ></el-dropdown-item>
                        <el-dropdown-item command="-1" divided>所有部门</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>

            <el-col :span="4">
                <el-date-picker
                        v-model="date"
                        type="date"
                        value-format="yyyyMMdd"
                        placeholder="请选择大致日期"
                        @change="chooseDate">
                </el-date-picker>
                <el-tooltip class="item" effect="dark" content="(只需选择相应周期中的一天)" placement="bottom-start">
                    <i class="el-icon-info"></i>
                </el-tooltip>
            </el-col>
            <el-col :span="4" >
                <el-dropdown @command="chooseType">
                    <el-button style="background-color: white">
                        {{this.TypeName}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="day">按日查看</el-dropdown-item>
                        <el-dropdown-item command="week">按周查看</el-dropdown-item>
                        <el-dropdown-item command="month">按月查看</el-dropdown-item>
                        <el-dropdown-item command="year">按年查看</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
<!--            v-if="this.showDate"-->
            <el-col :span="5">
                <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getAllChkTime">
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </el-col>
<!--            <el-col v-else :span="8">-->
<!--                <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getAllChkTime">-->
<!--                    <el-button slot="append" icon="el-icon-search"></el-button>-->
<!--                </el-input>-->
<!--            </el-col>-->
        </el-row>
            <el-row class="totals">
                <el-col :span="4" style="color: #009199">
                    <span style="color: #000000E6">当前部门：</span>
                    <b>{{this.deptName}}</b></el-col>
                <el-col :span="6" style="color: #009199">
                    <span style="color: #000000E6">巡视次数总计：</span>
                    <b>{{this.Counts}}</b><span style="color: #000000E6">次</span></el-col>
                <el-col :span="6" style="color: #009199">
                    <span style="color: #000000E6">巡视时长总计：</span>
                    <b>{{this.AllTimes}} </b><span style="color: #000000E6">分</span></el-col>
            </el-row>
            <el-table
                    :header-cell-style="{color: '#000000E6'}"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    :data="pagetestlist"
                    :stripe="true">
                <el-table-column align="center" prop="empId" label="员工编号"></el-table-column>
                <el-table-column align="center" prop="empName" label="员工姓名"></el-table-column>
                <el-table-column align="center" prop="deptName" label="部门名称">
                    <template slot-scope="scope">
                        <i class="el-icon-bank-card"></i>
                        {{scope.row.deptName}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="count" sortable label="巡视次数">
                    <template slot-scope="scope">
                        <i class="el-icon-s-data"></i>
                        <span v-if="scope.row.count" style="color:#009199;font-weight: bold;">{{scope.row.count}}</span>
                        <span v-else style="color: red;font-weight: bold">{{scope.row.count}}</span>次
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="time" sortable label="巡视总时长">
                    <template slot-scope="scope">
                        <i class="el-icon-alarm-clock"></i>
                        <span v-if="scope.row.time" style="color:#009199;font-weight: bold;">{{scope.row.time}}</span>
                        <span v-else style="color: red;font-weight: bold;">{{scope.row.time}}</span>分
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "chkStatic",
        data(){
            return{
                role: localStorage.getItem('role'),
                queryInfo: {
                    // 模糊搜索
                    searchInfo: '',
                    // 当前页数
                    pagenum: 1,
                    pagesize: 10
                },
                total: 0,
                AllChkTime:[],
                loading: true,
                date:'',
                type: '',
                title: '今日巡视统计',
                TypeName: '请选择查看周期',
                showDate: false,
                deptList:[],
                deptName: '请选择',
                deptId: '',
                NowTime:'', // 现在时间
                Counts: '', // 巡视次数总计
                AllTimes: '', //巡视时长总计
            }
        },
        created() {
            this.getNow()
            this.getDeptList()
        },
        methods:{
            getNow(){
                const D = new Date()
                const year = D.getFullYear()
                const month = (D.getMonth()+1) < 10 ? '0' + (D.getMonth()+1) : (D.getMonth()+1)
                const day = D.getDate()< 10 ? '0' + D.getDate() : D.getDate()
                const date = year + month + day
                // // console.log(date,D,year,month,day)
                this.deptId = '-1'
                this.type = 'day'
                this.date = date
                this.NowTime = date
                this.TypeName = '按日查找'
                // // console.log(this.type)
                this.getAllChkTime(this.date,this.type,this.deptId)
            },
            async getAllChkTime(date,type,deptId){
                // // console.log(date,type,deptId)
                const { data: res } = await this.$http.post('/check/chkPerson',{date:date, type:type})
                // // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                var list = res.data
                var arr =[]
                var Counts = 0
                var AllTimes = 0
                if (deptId === '-1'){
                    list.forEach(function (item) {
                        Counts += item.count
                        AllTimes += item.time
                    })
                    arr = res.data
                    this.deptName = '所有部门'
                }
                else{
                    list.forEach(function (item) {
                        if(item.deptId === deptId){
                            arr.push(item)
                            Counts += item.count
                            AllTimes += item.time
                        }
                    })
                    if (arr[0]){
                        this.deptName = arr[0].deptName
                    }
                    else {
                        this.$message.error('暂无该部门巡检统计')
                        }
                }
                this.AllChkTime = arr
                this.Counts = Counts
                this.AllTimes = AllTimes
                this.total = arr.length
                if (this.AllChkTime.length === arr.length){
                    this.loading = false
                }
            },
            // 监听 pagesize 改变的事件
            handleSizeChange (newSize) {
                this.queryInfo.pagesize = newSize
                this.getAllChkTime(this.date,this.type,this.deptId)
            },
            // 监听页码值改变的事件
            handleCurrentChange (newPage) {
                // // // console.log(newPage)
                this.queryInfo.pagenum = newPage
                this.getAllChkTime(this.date,this.type,this.deptId)
            },
            chooseType(type) {
                this.type = type
                if (type === 'week'){
                    this.TypeName = '按周查看'
                }
                else if (type === 'month'){
                    this.TypeName = '按月查看'
                }
                else if (type === 'year'){
                    this.TypeName = '按年查看'
                }
                else{
                    this.TypeName = '按日查看'
                }
                if (! this.date){
                    this.date = this.NowTime
                }
                this.title = this.TypeName + this.date.slice(0,4) + '年' +this.date.slice(4,6)+'月'+this.date.slice(6,8)+'日'
                this.getAllChkTime(this.date,type,this.deptId)
            },
            chooseDate(){
                // // console.log(this.date)
                // this.showDate = true
                if (!this.type){
                    this.$message.success('请您选择查看周期')
                }
                else{
                    this.getAllChkTime(this.date,this.type,this.deptId)
                }
            },
            // 获取所有部门
            async getDeptList () {
                if (this.role === 'employee'){
                    this.deptList = []
                }
                else if (this.role === 'admin') {
                    const { data: res } = await this.$http.get('/dept/allDept')
                    // // // console.log(res.data)
                    if (res.status !== 200) {
                        return this.$message.error(res.msg)
                    }
                    this.deptList = res.data
                }
                else {
                    const { data: res } = await this.$http.get('/dept/allBranch')
                    // // // console.log(res.data)
                    if (res.status !== 200) {
                        return this.$message.error(res.msg)
                    }
                    this.deptList = res.data
                }
            },
            // 点击选择部门按钮
            selectDept(deptId){
                // // console.log(deptId)
                this.deptId = deptId
                if (deptId === '-1') {
                    this.getAllChkTime(this.date,this.type,deptId)
                    this.deptName= '所有部门'
                }
                else {
                    this.getAllChkTime(this.date,this.type,deptId)
                }
            },
        },
        computed: {
            pagetestlist: function () {
                var search = this.queryInfo.searchInfo
                if (search) {
                    return this.AllChkTime.filter(function (data) {
                        return Object.keys(data).some(function (key) {
                            return String(data[key]).toLowerCase().indexOf(search) > -1
                        })
                    })
                }
                return this.AllChkTime.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
            }
        }
    }
</script>

<style scoped>
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    .totals{
        margin: 24px auto;
    }
</style>
