<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path : '/chkInfo'}">巡视记录管理</el-breadcrumb-item>
            <el-breadcrumb-item>隐患审核</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">上报隐患审核</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
<!--            功能栏-->
            <el-row type="flex" align="middle">
                <el-col :span="8">
                    <el-button class="addcount">
                        上报隐患审核
                    </el-button>
                    <el-button class="white_btn" @click="JumpTo()">
                        消除隐患审核
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-tag>
                        隐患类型
                    </el-tag>
                </el-col>
                <el-col :span="3">
                    <el-dropdown @command="selectType">
                      <el-button style="background-color: white">
                        {{this.dgeTypeName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in dgeType"
                                              v-text="item.dgeTypeName"
                                              :command="item.dgeTypeId"
                                              :key="index"
                            ></el-dropdown-item>
                            <el-dropdown-item command="-1" divided>所有类型</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col v-if="this.role == 'admin'" :span="2">
                    <el-tag>
                        选择部门
                    </el-tag>
                </el-col>
                <el-col v-if="this.role == 'manager'" :span="2">
                    <el-tag>
                        选择供电所
                    </el-tag>
                </el-col>
                <el-col v-if="this.role !== 'employee'" :span="3">
                    <el-dropdown @command="selectDept">
                      <el-button style="background-color: white">
                        {{this.deptName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in deptList"
                                              v-text="item.deptName"
                                              :command="item.deptId"
                                              :key="index"
                            ></el-dropdown-item>
                            <el-dropdown-item command="-1" v-if="this.role === 'manager'" divided>所有供电所</el-dropdown-item>
                            <el-dropdown-item command="-1" v-if="this.role === 'admin'" divided>所有部门</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col v-if="this.role !== 'employee' " :span="5">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getDgeList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col v-if="this.role === 'employee' " :span="8">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getDgeList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4" v-if="selected[0]">
                    <el-button class="addcount" @click="ArrayToCheck">
                        <svg class="icon_add"  viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M17.7816966,17.4453094 L15.4302196,15.0910579 C16.0416367,14.2594411 16.403513,13.2331138 16.403513,12.1236327 C16.403513,9.3575449 14.1557485,7.1070459 11.3929741,7.1070459 C8.63019961,7.1070459 6.38251496,9.35752494 6.38251496,12.1236327 C6.38251496,14.8897605 8.63019959,17.1402395 11.392974,17.1402395 C12.5011178,17.1402395 13.5262076,16.7779241 14.3568463,16.1657685 L16.7083433,18.52002 C16.8565868,18.6683234 17.0507585,18.7425349 17.2450299,18.7425349 C17.4393213,18.7425349 17.633473,18.6683233 17.7817165,18.52 C18.0780838,18.2231737 18.0780838,17.7420359 17.7816966,17.4453094 Z M11.392974,15.9054491 C9.31019959,15.9054491 7.61582834,14.2089022 7.61582834,12.1236327 C7.61582834,10.0383633 9.31019959,8.34183633 11.392974,8.34183633 C13.4757285,8.34183633 15.1702196,10.0383433 15.1702196,12.1236327 C15.1702196,14.2089221 13.4757285,15.9054491 11.392974,15.9054491 Z M4.90654691,12.3313373 C4.90654691,8.79053893 7.7733533,5.92015969 11.3097405,5.92015969 C13.4753693,5.92015969 15.3899002,6.99658684 16.5487226,8.64423154 L16.5487226,2.42506986 C16.5487226,1.78077844 16.0265469,1.25748504 15.3824152,1.25748504 L3.16231537,1.25748504 C2.51822355,1.25748504 1.99600799,1.78021957 1.99600799,2.4250499 L1.99600799,17.5749102 C1.99600799,18.2192216 2.51818363,18.742515 3.16231537,18.742515 L11.3097405,18.742515 C7.7733533,18.742515 4.90654691,15.8721357 4.90654691,12.3313373 Z M4.32445109,3.58882236 C4.32445109,3.26692615 4.58506986,3.00598803 4.90656686,3.00598803 L13.6382036,3.00598803 C13.9596806,3.00598803 14.2203193,3.26692615 14.2203193,3.58882236 C14.2203193,3.91069861 13.9597006,4.1716567 13.6382036,4.1716567 L4.90654691,4.1716567 C4.58506986,4.1716567 4.32445109,3.91069859 4.32445109,3.58882236 Z" id="形状" fill="#FFFFFF"></path>
                        </svg>
                        批量审核
                    </el-button>
                </el-col>
            </el-row>
<!--            展示所有隐患-->
            <el-table
                    :header-cell-style="{color: '#000000E6'}"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    ref="multipleTable"
                    :data="pagetestlist"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :row-key="getRowKeys"
                    :expand-row-keys="expands"
                    @expand-change="expandChange">
                <!--                多选-->
                <el-table-column
                        fixed="left"
                        type="selection"
                        width="30">
                </el-table-column>
                <!--                展开行-->
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left">
                            <el-form-item>
                                 <span slot="label">
                                     <i class="el-icon-bank-card"></i>
                                     隐患名称
                                </span>
                                <span style="color: #009199">{{scope.row.dgeName}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                 <i class="el-icon-house"></i>
                                 所属部门
                                </span>
                                <span style="color: #0077aa">{{dgeList.deptName}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-user-solid"></i>
                                     上报人
                                </span>
                                <span>{{dgeList.founder}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-location"></i>
                                     地址
                                </span>
                                <span>{{dgeList.address}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-info"></i>
                                     隐患描述
                                </span>
                                <span>{{dgeList.info}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-picture"></i>
                                     现场照片
                                </span>
                                <div v-if="dgeList.img[0] === 'null'">
                                    <el-col :span="3">
                                        <svg class="Xc_pic" viewBox="0 0 102 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <path d="M21.1470999,3.91629139 L3.61938683,3.91629139 C2.99339708,3.91629139 2.47787611,4.43797648 2.47787611,5.07145122 L2.47787611,19.40537 C2.47787611,20.0388447 2.99339708,20.5605298 3.61938683,20.5605298 L21.1593742,20.5605298 C21.785364,20.5605298 22.300885,20.0388447 22.300885,19.40537 L22.300885,5.07145122 C22.2886106,4.43797648 21.785364,3.91629139 21.1470999,3.91629139 Z M20.5456588,17.8651569 C20.5456588,17.5794722 20.5456588,18.7843163 19.5514398,18.7843163 L4.98183512,18.7843163 C4.22082797,18.7843163 4.22082797,18.1632626 4.22082797,18.1632626 L4.22082797,17.0081028 C4.22082797,17.0081028 4.45403984,16.6603127 4.68725171,16.4243123 L8.1363325,12.9339907 C8.1363325,12.9339907 8.29589852,12.80978 8.41864161,12.80978 C8.553659,12.80978 8.72549933,12.9464118 8.72549933,12.9464118 L10.7875832,15.0579943 C10.9348749,15.2070471 11.1680868,15.2070471 11.3153785,15.0579943 L15.7709526,10.4994603 C15.7709526,10.4994603 15.9305186,10.3628285 16.1146333,10.3628285 C16.2987479,10.3628285 16.4583139,10.5118814 16.4583139,10.5118814 L20.079235,14.1760981 C20.3124469,14.3996774 20.5456588,14.7350464 20.5456588,14.7350464 L20.5456588,17.8651569 L20.5456588,17.8651569 Z" id="形状" fill="#DCE0E6"></path>
                                            <path d="M5.45132743,8.07735099 C5.45132743,8.75866457 6.01331995,9.30119205 6.69026549,9.30119205 C7.37998358,9.30119205 7.92920354,8.74604765 7.92920354,8.07735099 C7.92920354,7.39603741 7.36721102,6.85350993 6.69026549,6.85350993 C6.01331995,6.85350993 5.45132743,7.39603741 5.45132743,8.07735099 L5.45132743,8.07735099 Z" id="路径" fill="#FFFFFF"></path>
                                        </svg>
                                    </el-col>
                                    暂无
                                </div>
                                <div v-else>
                                    <el-col :span="3" v-for="(item,index) in dgeList.img" :key="index">
                                        <el-image fit="cover" class="XC_pic" :src="item">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </el-col>
                                </div>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="dgeName" label="隐患点名称" align="center"></el-table-column>
                <el-table-column prop="dgeTypeName" label="隐患类型" align="center">
                    <template slot-scope="scope">
                        <i class="el-icon-collection-tag"></i>
                        {{scope.row.dgeTypeName}}
                    </template>
                </el-table-column>
                <el-table-column prop="dgeLevel" label="隐患等级" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag class="normal" v-if="scope.row.dgeLevel == '一般'">
                            {{scope.row.dgeLevel}}
                        </el-tag>
                        <el-tag class="serious" v-if="scope.row.dgeLevel == '严重'">
                            {{scope.row.dgeLevel}}
                        </el-tag>
                        <el-tag class="important" v-if="scope.row.dgeLevel == '重要'">
                            {{scope.row.dgeLevel}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" sortable label="上报时间" align="center">
                    <template slot-scope="scope">
                        <i class="el-icon-date"></i>
                        {{scope.row.updateTime}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center"  fixed="right">
                    <template slot-scope="scope">
                       <el-button type="text" @click="checkFound(scope.row.dgeId, 'yes')">审核通过</el-button>
                        <el-button type="text" @click="checkFound(scope.row.dgeId, 'fail')" style="color: red; padding-left: 4px">不通过</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </el-card>
        <baidu-map ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I"></baidu-map>
        <el-dialog
                title="批量审核上报隐患"
                :visible.sync="showToArray"
                width="47.9%"  @close="checkDialogClosed">
            <!--      底部区域-->
<!--            <span slot="footer" class="dialog-footer">-->
            <el-col align="center">请选择巡视周期(默认为一周)</el-col>

            <div  style="text-align: center;margin-top: 30px">
                <el-select v-model="dgeArrayCycle" style="width: 150px" placeholder="若通过，请选择巡视周期(默认为一周)">
                    <el-option label="一周(7天)" value=7></el-option>
                    <el-option label="半个月(15天)" value=15></el-option>
                    <el-option label="一个月(30天)" value=30></el-option>
                    <el-option label="两个月(60天)" value=60></el-option>
                </el-select>
            </div>
                <el-row style="margin-top: 30px">
                    <el-col :span="4" :offset="5">
                        <el-button class="green_btn" @click="Manycheck('yes',dgeArrayCycle)">通 过</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="red_btn" @click="Manycheck('fail',0)">不通过</el-button>
                    </el-col>
                </el-row>
<!--            </span>-->
        </el-dialog>
        <el-dialog
                title="选择巡视周期"
                :visible.sync="showCycle"
                width="47.9%"  @close="CycleDialogClosed">
            <el-col align="center">请选择巡视周期(默认为一周)</el-col>

            <div style="text-align: center;margin-top: 30px">
                <el-select v-model="dgeCycle" style="width: 150px" placeholder="请选择巡视周期(默认为一周)">
                    <el-option label="一周(7天)" value=7></el-option>
                    <el-option label="半个月(15天)" value=15></el-option>
                    <el-option label="一个月(30天)" value=30></el-option>
                    <el-option label="两个月(60天)" value=60></el-option>
                </el-select>
            </div>
<!--                  底部区域-->

            <el-row>
                <el-col :span="4" :offset="10" style="margin-top: 20px">
                    <el-button class="addcount" @click="check(checkDgeId,'yes',dgeCycle)"> 确 定</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
    import {BaiduMap} from "vue-baidu-map/components";
    export default {
    name: "checkDge",
    components: {
        BaiduMap
    },
    data(){
        return{
            checkDgeId: '',
            dgeCycle: 7,
            dgeArrayCycle: 7,
            role: localStorage.getItem('role'),
            // 隐患类型
            dgeType: [],
            loading:true,
            dgeTypeName: '选择类型',
            // 部门
            deptList: [],
            deptName: '选择部门',
            queryInfo: {
                // 模糊搜索
                searchInfo: '',
                // 当前页数
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            AllDgelist: [], // 所有隐患
            selected: [],
            founder : '', // 上报者
            show: false,
            dgeList: {
                founder: '', // 上报人
                deptName: '', // 部门名称
                address: '解析失败', // 隐患地址
                info: '', // 隐患文字描述
                img: '', //现场照片
            },
            //设置row-key只展示一行
            expands: [],
            getRowKeys(row) {//只展开一行放入当前行id
                return row.dgeId
            },
            showToArray: false, // 批量
            showCycle: false,
            baseUrl: "http://ele.qiniu.heilama.com/",
        }
    },
    created() {
        this.getDgeList()
        this.getDgeType()
        this.getDeptList()
    },
    methods:{
        // 获取隐患所有类型
        async getDgeType () {
            const { data: res } = await this.$http.get('/type/allType')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.dgeType = res.data
        },
        selectType(Id){
            if(Id == -1) {
                this.getDgeList()
                this.dgeTypeName = '选择类型'
            }
            else{
                this.getOneDgeType(Id)
            }
        },
        async getOneDgeType(Id) {
            const { data: res } = await this.$http.get('/danger/findByType/'+ Id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (res.data[0]){
                this.dgeTypeName =  res.data[0].dgeTypeName
                this.AllDgelist = res.data
                this.total = res.data.length
            }
            else {
                this.$message.warning('暂无该类型隐患')
                this.dgeTypeName = '选择类型'
            }

            // this.total = res.data.length
        }
        ,
        // 获取所有部门
        async getDeptList () {
            if (this.role === 'employee'){
                this.deptList = []
            }
            else if (this.role === 'admin') {
                const { data: res } = await this.$http.get('/dept/allDept')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList = res.data
            }
            else {
                const { data: res } = await this.$http.get('/dept/allBranch')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList = res.data
            }

        },
        // 点击选择部门按钮
        selectDept(deptId){
            // console.log(deptId)
            if (deptId == -1) {
                this.getDgeList()
                this.deptName= '所有部门'
            }
            else {
                this.getOnedept(deptId)
                this.getDeptName(deptId, 'list')
            }
        },
        // 获取部门名称
        async getDeptName(id,style){
            const { data: res } = await this.$http.get('/dept/showInfo/' + id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (style === 'row'){
                this.dgeList.deptName = res.data.deptName
            }
            else if (style === 'list') {
                this.deptName = res.data.deptName
            }
        },
        // 选中其中一个部门
        async getOnedept(deptId){
            const {data: res } = await this.$http.get('/danger/findByDept/' + deptId)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (res.data[0]){
                // var id = res.data[0].deptId
                // this.getDeptName(id,'list')
                this.AllDgelist = res.data
                this.total = res.data.length
            }
            else {
                this.$message.warning('暂无属于该部门的隐患')
                this.dgeTypeName = '选择部门'
            }

        },
        // 获取未审核的隐患情况
        async getDgeList () {
            const { data: res } = await this.$http.get('/danger/findByStatus/no')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
                this.AllDgelist = res.data
                this.total = res.data.length
                this.loading = false
        },
        // 选中该行
        handleSelectionChange(val) {
            this.selected = val;
            // console.log(val)
        },
        // 监听 pagesize 改变的事件
        handleSizeChange (newSize) {
            this.queryInfo.pagesize = newSize
            this.getDgeList()
        },
        // 监听页码值改变的事件
        handleCurrentChange (newPage) {
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getDgeList()
        },
        // 展开事件----动态获取内嵌表数据
        expandChange(row, expandedRows) {
            // console.log(expandedRows.length)
            var that = this
            if (expandedRows.length) {
                that.expands = []
                if (row) {
                    that.expands.push(row.dgeId)// 每次push进去的是每行的ID
                    var FounderId = row.dgeFound
                    that.getDgeFounder(FounderId)
                    // 将腾讯地图经纬度转换为百度地图经纬度
                    var dgeLng = row.dgeLng //经度
                    var dgeLat = row.dgeLat
                    var x_pi = 3.14159265358979324;
                    var x = parseFloat(dgeLng);
                    var y = parseFloat(dgeLat);
                    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                    var lng = (z * Math.cos(theta) + 0.0065);
                    var lat = (z * Math.sin(theta) + 0.006);
                    // 调用百度地图api解析经纬度得到地址
                    that.getAddressName(lng, lat)
                    that.getDeptName(row.deptId,'row')
                    var info = (row.dgeInfo+ "").split('imgGroup:')[0]
                    that.dgeList.info = (info === 'null') ? '暂无隐患信息' : info
                    var imgstr = (row.dgeInfo+ "").split('imgGroup:')[1]
                    if(imgstr) {
                        var arr = (imgstr+ "").split(",")
                        var list = []
                        arr.forEach(function (item) {
                            list.push(that.baseUrl + item)
                        })
                        that.dgeList.img = list
                    }
                    else {
                        that.dgeList.img = ["null"]
                    }
                }
            } else {
                that.expands = []// 默认不展开
            }

        },
        // 根据坐标逆解析地址
        getAddressName(lng,lat){
            let that = this
            // 创建解析器
            var myGeo = new window.BMap.Geocoder();
            var pt = new window.BMap.Point(lng,lat);// 随便写个经纬度
            myGeo.getLocation(pt,function(result){
                // console.log(result);
                that.dgeList.address = result.address
            });

        },
        //  获取上报人名字
        async getDgeFounder (id) {
            const { data: res } = await this.$http.get('/emp/showInfo/' + id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.dgeList.founder = res.data.empName
            // console.log(this.dgeList.founder)
        },
    //    上报模式选择
        JumpTo(){
            this.$router.push({path:'/chkInfo'})
        },
    //    上报审核处理
        async checkFound (id, status) {
            // this.checkDgeId = id
            const confirmResult = await this.$confirm('此操作将对上报隐患进行审核, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消审核')
            }
            if (status === 'yes') {
                this.showCycle = true
                this.checkDgeId = id
            }
            else {
                this.check(id, status,0)
            }

        },
        CycleDialogClosed(){
          this.showCycle = false
          this.dgeCycle = 7
        },
        checkDialogClosed(){
            this.showToArray = false
        },
        async check(id, status, dgeCycle){
            if (status === 'yes') {
                dgeCycle = this.dgeCycle
            }
            // console.log(id,status,dgeCycle)
            const { data: res } = await this.$http.put('/danger/checkFound',{
                dgeId: id , dgeStatus: status, dgeCycle: dgeCycle
            })
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if(status === 'fail'){
                this.$message.success('您已完成该隐患审核，即不通过')

            }
            else if(status === 'yes') {
                this.$message.success('您已完成该隐患审核，即通过')
            }
            this.showCycle = false
            this.getDgeList()
        },
    //    批量处理审核
        ArrayToCheck(){
            this.showToArray = true
            // console.log(this.selected)
        },
        async Manycheck(type, dgeCycle){
            this.showToArray = false
            const confirmResult = await this.$confirm('此操作将批量执行对上报隐患的审核, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 若用户确认删除，则返回值为字符串 confirm
            // 若取消删除，返回 cancel
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消批量审核')
            }
            if (type === 'yes') {
                dgeCycle = this.dgeArrayCycle
            }
            var arr = []
            this.selected.forEach(function (item) {
                arr.push({dgeId:item.dgeId,dgeStatus:type,dgeCycle:dgeCycle})
            })
            // console.log(arr)
            const { data: res } = await this.$http.put('/danger/checkFoundMulti',arr)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success('批量审核成功!')
            this.showToArray = false
            // this.getDgeList()
        },
    },
    computed: {
        pagetestlist: function () {
            var search = this.queryInfo.searchInfo
            if (search) {
                return this.AllDgelist.filter(function (data) {
                    return Object.keys(data).some(function (key) {
                        return String(data[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return this.AllDgelist.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
        }
    }
}
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .white_btn{
        letter-spacing: 2px;
        color: #009199;
    }
    .white_btn:hover,.white_btn:focus{
        background: #00B6C0;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .red_btn{
        letter-spacing: 2px;
        color: #009199;
        width: 7.3vw;
        height: 7.3vw;
        border-radius: 100px;
        font-size: 32px;
        font-weight: bold;
        border: 2px solid #009199;
        margin-bottom: 3.7vh;
    }
    .red_btn:hover,.red_btn:focus{
        background: #0086b3;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .green_btn{
        background: #009199;
        color: white;
        letter-spacing: 2px;
        width: 7.3vw;
        height: 7.3vw;
        border-radius: 100px;
        font-size: 32px;
        font-weight: bold;
        border: 2px solid white;
        margin-bottom: 3.7vh;
    }
    .green_btn:hover,green_btn:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .icon_add{
        width: 20px;
        height: 20px;
        padding-right: 2px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    /*标签一般*/
    .normal{
        background: #FF9900;
        color: white;
        border-radius: 2px;
        border: 1px solid #FF9990;
    }
    /*标签重要*/
    .important{
        background: #D8262A;
        color: white;
        border-radius: 2px;
        border: 1px solid #D82620;
    }
    /*标签严重*/
    .serious{
        background: #8F2139;
        color: white;
        border-radius: 2px;
        border: 1px solid #8F2100;
    }

    /deep/.el-checkbox__inner:hover {
        border-color: #009199;
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: white;
    }
    /deep/.el-checkbox__inner{
        /*缩放*/
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: #009199;
    }
    /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    /deep/.el-form-item__label {
        width: 180px;
        color: #99a9bf;
        margin-left: 40px;
    }
    /deep/.el-dropdown{
        cursor: pointer;
    }
</style>

<style lang="less" scoped>
    .el-button+.el-button{
        margin-left: 0px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #009199;
        border-color: #009199;
    }
    .el-checkbox__inner.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    .el-table{
        margin-top: 44px;
    }
    /*展开行css*/
    .XC_pic{
        width: 90px ;
        height: 90px;
    }
</style>
