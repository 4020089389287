<template>
    <div style="width: 100%; height: 100%">
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/Static'}">数据统计</el-breadcrumb-item>
            <el-breadcrumb-item><span class="hight">今日巡检记录</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!--            功能栏-->
            <el-row type="flex" align="middle">
                <el-col :span="7" style="font-weight: bold">{{this.title}}</el-col>
                <el-col :span="2">
                    <el-tag>
                        巡视类型
                    </el-tag>
                </el-col>
                <el-col :span="3">
                    <el-dropdown @command="selectType">
                      <el-button style="background-color: white">
                        {{this.chkTypeName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in chkType"
                                              v-text="item.TypeName"
                                              :command="item.id"
                                              :key="index"
                            ></el-dropdown-item>
                            <el-dropdown-item command="-1" divided>所有类型</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col v-if="this.role == 'admin'" :span="2">
                    <el-tag>
                        选择部门
                    </el-tag>
                </el-col>
                <el-col v-if="this.role == 'manager'" :span="2">
                    <el-tag>
                        选择供电所
                    </el-tag>
                </el-col>
                <el-col v-if="this.role !== 'employee'" :span="3">
                    <el-dropdown @command="selectDept">
                      <el-button style="background-color: white">
                        {{this.deptName}}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in deptList"
                                              v-text="item.deptName"
                                              :command="item.deptId"
                                              :key="index"
                            ></el-dropdown-item>
                            <el-dropdown-item command="-1" divided>所有部门</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
                <el-col v-if="this.role !== 'employee' " :span="6">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getChkList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col v-if="this.role === 'employee' " :span="8">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getChkList">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <!--            展示所有隐患-->
            <el-table
                    :header-cell-style="{color: '#000000E6'}"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    ref="multipleTable"
                    :data="pagetestlist"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :row-key="getRowKeys"
                    :expand-row-keys="expands"
                    @expand-change="expandChange">
                <!--                多选-->
                <el-table-column
                        fixed="left"
                        type="selection"
                        width="30">
                </el-table-column>
                <!--                展开行-->
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left" style="margin-left: 40px;">
                            <el-form-item>
                                 <span slot="label">
                                     <i class="el-icon-bank-card"></i>
                                     隐患名称
                                </span>
                                <span style="color: #009199">{{scope.row.dgeName}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-s-home"></i>
                                     所属部门
                                    </span>
                                <span style="color: #0077aa">{{chkList.deptName}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-user-solid"></i>
                                     巡视人员
                                </span>
                                <span>{{chkList.person}}</span>
                            </el-form-item>

                            <el-form-item >
                                <span slot="label">
                                     <i class="el-icon-location"></i>
                                     地址
                                </span>
                                <span>{{chkList.address}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-info"></i>
                                     巡视信息
                                </span>
                                <span>{{chkList.info}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span slot="label">
                                     <i class="el-icon-picture"></i>
                                     现场照片
                                </span>
                                <div v-if="chkList.img[0] === 'null'">
                                    <el-col :span="3">
                                        <svg class="Xc_pic" viewBox="0 0 102 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <path d="M21.1470999,3.91629139 L3.61938683,3.91629139 C2.99339708,3.91629139 2.47787611,4.43797648 2.47787611,5.07145122 L2.47787611,19.40537 C2.47787611,20.0388447 2.99339708,20.5605298 3.61938683,20.5605298 L21.1593742,20.5605298 C21.785364,20.5605298 22.300885,20.0388447 22.300885,19.40537 L22.300885,5.07145122 C22.2886106,4.43797648 21.785364,3.91629139 21.1470999,3.91629139 Z M20.5456588,17.8651569 C20.5456588,17.5794722 20.5456588,18.7843163 19.5514398,18.7843163 L4.98183512,18.7843163 C4.22082797,18.7843163 4.22082797,18.1632626 4.22082797,18.1632626 L4.22082797,17.0081028 C4.22082797,17.0081028 4.45403984,16.6603127 4.68725171,16.4243123 L8.1363325,12.9339907 C8.1363325,12.9339907 8.29589852,12.80978 8.41864161,12.80978 C8.553659,12.80978 8.72549933,12.9464118 8.72549933,12.9464118 L10.7875832,15.0579943 C10.9348749,15.2070471 11.1680868,15.2070471 11.3153785,15.0579943 L15.7709526,10.4994603 C15.7709526,10.4994603 15.9305186,10.3628285 16.1146333,10.3628285 C16.2987479,10.3628285 16.4583139,10.5118814 16.4583139,10.5118814 L20.079235,14.1760981 C20.3124469,14.3996774 20.5456588,14.7350464 20.5456588,14.7350464 L20.5456588,17.8651569 L20.5456588,17.8651569 Z" id="形状" fill="#DCE0E6"></path>
                                            <path d="M5.45132743,8.07735099 C5.45132743,8.75866457 6.01331995,9.30119205 6.69026549,9.30119205 C7.37998358,9.30119205 7.92920354,8.74604765 7.92920354,8.07735099 C7.92920354,7.39603741 7.36721102,6.85350993 6.69026549,6.85350993 C6.01331995,6.85350993 5.45132743,7.39603741 5.45132743,8.07735099 L5.45132743,8.07735099 Z" id="路径" fill="#FFFFFF"></path>
                                        </svg>
                                    </el-col>
                                    暂无
                                </div>
                                <div v-else>
                                    <br>
                                    <div  v-for="(src,index) in chkList.img" :key="index">
                                        <el-col :span="4" class="img_pad">
                                            <viewer :images="chkList.img" class="XC_pic">
                                                <img :src="src" style="width:100%;">
                                            </viewer>
                                        </el-col>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <el-button plain style="color: #009199; margin-left: 60px" @click="Judge(scope.row.dgeId,scope.chkId)">消除隐患审核</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="dgeName" label="隐患点名称" align="center"></el-table-column>
                <el-table-column prop="chkType" label="巡视类型" align="center">
                    <template slot-scope="scope">
                        <span style="color: #009199" v-if="scope.row.chkType === 'general'">
                           一般巡视
                        </span>
                        <span style="color: orangered" type="danger" v-if="scope.row.chkType === 'final'">
                            申请消除隐患
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="chkResult" label="审核结果" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.chkResult == 'null'">
                            暂无审核结果
                        </el-tag>
                        <el-tag type="success" v-if="scope.row.chkResult == 'accept'">
                            {{scope.row.chkResult}}
                        </el-tag>
                        <el-tag type="danger" v-if="scope.row.chkResult == 'refuse'">
                            {{scope.row.chkResult}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" sortable label="巡视最新时间" align="center">
                    <template slot-scope="scope">
                        <i class="el-icon-date"></i>
                        {{scope.row.updateTime}}
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" sortable label="巡视时长" align="center">
                    <template slot-scope="scope">
                        <i class="el-icon-alarm-clock"></i>
                        {{scope.row.chkMin}}分
                    </template>
                </el-table-column>
                <el-table-column v-if="this.role === 'employee'" label="操作" align="center"  fixed="right">
                    <template slot-scope="scope">
                        <el-button  type="text" @click="EditChkInfo(scope.row)">修改</el-button>
                        <el-button  type="text" style="color: red; padding-left: 4px" @click="removeChk(scope.row.chkId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--      分页区域-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pagenum"
                    :page-sizes="[10, 15, 20, 30]"
                    :page-size="queryInfo.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
            <br>
            <el-row>
                <el-col :span="6">
                    <el-button class="addcount" @click="checkJudge()" v-show="this.selected[0]">
                        <i class="el-icon-remove"></i>
                        消除隐患审核
                    </el-button>
                </el-col>
            </el-row>
        </el-card>
<!--        创建新巡视记录-->
        <!--        编辑部门信息-->
        <el-dialog
                title="修改巡视记录"
                :visible.sync="ToOpenChk"
                width="47.9%" @close="ChkDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="ChkForm" status-icon ref="ChkFormRef" label-width="116px">
                <el-form-item label="巡视编号">
                    <el-input v-model="ChkForm.chkId" disabled></el-input>
                </el-form-item>
                <el-form-item label="巡视类型">
                    <el-select  v-model="ChkForm.chkType" placeholder="请选择巡视类型">
                        <el-option
                                v-for="item in chkType"
                                :key="item.id"
                                :label="item.TypeName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="巡视信息">
                    <el-input type="textarea" :rows="4" v-model="ChkForm.chkInfo"></el-input>
                </el-form-item>
                <el-form-item label="巡视所处经度">
                    <el-input v-model="ChkForm.chkLng"></el-input>
                </el-form-item>
                <el-form-item label="巡视所处纬度">
                    <el-input v-model="ChkForm.chkLat"></el-input>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
<!--          <el-button @click="resetForm">重置修改</el-button>-->
                <el-row>
                    <el-col :span="4" :offset="10">
                          <el-button class="addcount" @click="editInfos">确定修改</el-button>
                    </el-col>
                </el-row>
        </span>
        </el-dialog>
        <baidu-map ak="TRtS1u3t3j3hgXzUy6F4bULjdxmPTz3I"></baidu-map>

    </div>
</template>

<script>
import {BaiduMap} from "vue-baidu-map/components";

export default {
    name: "chkInfo",
    components: {
        BaiduMap
    },
    data(){
        return{
            baseUrl: "http://ele.qiniu.heilama.com/",
            empId : localStorage.getItem('empId'),
            role: localStorage.getItem('role'),
            //设置row-key只展示一行
            expands: [],
            getRowKeys(row) {//只展开一行放入当前行id
                return row.chkId
            },
            // 巡视类型
            chkType: [{TypeName:'一般巡视',id: 'general'},{TypeName: '申请消除隐患',id:'final'}],
            chkTypeName: '选择类型',
            // 部门
            deptList: [],
            deptName: '选择部门',
            AllChkList: [], // 所有巡视记录
            queryInfo: {
                // 模糊搜索
                searchInfo: '',
                // 当前页数
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
        //    展开行内容
            chkList: {
                person: '',
                deptName: '',
                address: '解析失败',
                info: '',
                img: [], //附带照片
            },
        //    打开创建巡视记录的窗口
            ToOpenChk: false,
            ChkForm: {
                chkType: '',
                dgeId: '',
                chkInfo: '',
                chkLng: '',
                chkLat: '',
                chkId:''
            },
        //    选中的巡视记录
            selected: [],
            loading:true,
            title: ''
        }
    },
    created() {
        this.getType()
        this.getDeptList()
    },
    methods:{
        getType(){
            var type = this.$route.query.type
            // console.log(this.$route.query.type)
            if (type === 'chkNum'){
                this.title = '今日巡检记录'
            }
            this.getChkList(type)
        },
        // 获取所有巡视记录
        async getChkList (type) {
            // console.log(role)
            const { data: res } = await this.$http.get('/check/findAll/')
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            var list = res.data
            var arr = []
            if (type === 'chkNum'){
                var nowTime = new Date();
                // console.log(nowTime.getDay())
                list.forEach(function (item) {
                    var time = item.createTime.substring(0,19).replace(/-/g,'/')
                    var date = new Date(time)
                    if ( nowTime.getDay()=== date.getDay() && nowTime.getMonth() === date.getMonth() && nowTime.getFullYear()===date.getFullYear()) {
                        arr.push(item)
                    }
                })
            }
                this.AllChkList = arr
                this.total = arr.length
                if(this.AllChkList.length === arr.length){
                    this.loading = false
                }
        },
        // 获取所有部门
        async getDeptList () {
            if (this.role === 'employee'){
                this.deptList = []
            }
            else if (this.role === 'admin') {
                const { data: res } = await this.$http.get('/dept/allDept')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList = res.data
            }
            else {
                const { data: res } = await this.$http.get('/dept/allBranch')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.deptList = res.data
            }
        },
        selectType(Id){
            if(Id == -1) {
                this.getChkList()
                this.dgeTypeName = '选择类型'
            }
            else{
                this.getChkType(Id)
            }
        },
        // 按类型查找巡视记录
        async getChkType(chkType) {
            const { data: res } = await this.$http.get('/check/findByType/' + chkType)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (res.data[0]){
                if (res.data[0].chkType === 'general'){
                    this.chkTypeName = '一般巡视'
                }
                else if(res.data[0].chkType === 'final'){
                    this.chkTypeName = '申请消除隐患'
                }
                this.AllChkList = res.data
                this.total = res.data.length
            }
            else {
                this.$message.warning('暂无该类型巡视记录')
                this.chkTypeName = '选择类型'
                this.getChkList()
            }
        },
        // 点击选择部门按钮
        selectDept(deptId){
            // console.log(deptId)
            if (deptId == -1) {
                this.getChkList()
                this.deptName= '所有部门'
            }
            else {
                this.getOnedept(deptId)
            }
        },
        // 选中其中一个部门
        async getOnedept(deptId){
            const {data: res } = await this.$http.get('/check/findByDept/' + deptId)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (res.data[0]){
                var id = res.data[0].deptId
                this.getDeptName(id,'list')
                this.AllChkList = res.data
                this.total = res.data.length
            }
            else {
                this.$message.warning('暂无属于该部门的隐患')
                this.deptName = '选择部门'
                this.getChkList()
            }
        },
        // 选中该行
        handleSelectionChange(val) {
            this.selected = val;
            // console.log(val)
        },
        // 监听 pagesize 改变的事件
        handleSizeChange (newSize) {
            this.queryInfo.pagesize = newSize
            this.getChkList()
        },
        // 监听页码值改变的事件
        handleCurrentChange (newPage) {
            // console.log(newPage)
            this.queryInfo.pagenum = newPage
            this.getChkList()
        },
        // 展开事件----动态获取内嵌表数据
        expandChange(row, expandedRows) {
            // console.log(expandedRows.length)
            var that = this
            if (expandedRows.length) {
                that.expands = []
                if (row) {
                    that.expands.push(row.chkId)// 每次push进去的是每行的ID
                    var PersonId = row.empId
                    var deptId = row.deptId
                    that.getChkPerson(PersonId)
                    that.getDeptName(deptId, 'row')
                    // 调用百度地图api解析经纬度得到地址
                    var dgeLng = row.chkLng //经度
                    var dgeLat = row.chkLat
                    that.getAddressName(dgeLng, dgeLat)
                    var info = (row.chkInfo+ "").split('imgGroup:')[0]
                    that.chkList.info = (info === 'null') ? '暂无巡视信息' : info
                    var imgstr = (row.chkInfo+ "").split('imgGroup:')[1]
                    if(imgstr) {
                        var arr = (imgstr+ "").split(",")
                        var list = []
                        arr.forEach(function (item) {
                            list.push(that.baseUrl + item)
                        })
                        that.chkList.img = list
                    }
                    else {
                        that.chkList.img = ["null"]
                    }
                    // console.log(that.chkList.img, info)
                }
            }
            else {
                that.expands = []// 默认不展开
            }
        },
        // 根据坐标逆解析地址
        getAddressName(lng,lat){
            let that = this
            // 创建解析器

            var myGeo = new window.BMap.Geocoder();
            var pt = new window.BMap.Point(lng,lat);// 随便写个经纬度
            myGeo.getLocation(pt,function(result){
                // console.log(result);
                that.chkList.address = result.address
            });

        },
        // 获取巡视人员名字
        async getChkPerson(id){
            const { data: res } = await this.$http.get('/emp/showInfo/' + id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.chkList.person = res.data.empName
        },
        // 获取部门名称
        async getDeptName(id,style){
            const { data: res } = await this.$http.get('/dept/showInfo/' + id)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            if (style === 'row'){
                this.chkList.deptName = res.data.deptName
            }
            else if (style === 'list') {
                this.deptName = res.data.deptName
            }
        },
    //    监听修改巡视记录的关闭事件
        ChkDialogClosed () {
            this.$refs.ChkFormRef.resetFields()
        },
        EditChkInfo(list){
            // console.log(list)
            this.ToOpenChk = true
            this.ChkForm = list
        },
        // 修改巡视记录
        async editInfos () {
            const { data: res } = await this.$http.put('/check/update', this.ChkForm)
            // console.log(res.data)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success('修改巡视记录成功!')
        },
    //    删除巡视记录
        async removeChk (id) {
            // console.log(id)
            const confirmResult = await this.$confirm('此操作将永久删除该巡视记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 若用户确认删除，则返回值为字符串 confirm
            // 若取消删除，返回 cancel
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消删除')
            }
            // console.log('确认删除')
            const { data: res } = await this.$http.delete('/check/delete/' + id)
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success('删除记录成功!')
            this.getChkList()
        },
    //    消除隐患审核
        async checkJudge(){
            const confirmResult = await this.$confirm('您将确认审核该巡视记录中此隐患, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 若用户确认删除，则返回值为字符串 confirm
            // 若取消删除，返回 cancel
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消审核')
            }
            // console.log('确认删除')
            const { data: res } = await this.$http.put('/danger/checkJudge',{
                dgeId: this.selected[0].dgeId, dgeStatus: 'done', chkId: this.selected[0].chkId
            })
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success('审核成功!')
        },
        async Judge(dgeId, chkId){
            const confirmResult = await this.$confirm('您将确认审核该巡视记录中此隐患, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)
            // 若用户确认删除，则返回值为字符串 confirm
            // 若取消删除，返回 cancel
            if (confirmResult !== 'confirm') {
                return this.$message.info('已取消审核')
            }
            // console.log('确认删除')
            const { data: res } = await this.$http.put('/danger/checkJudge',{
                dgeId: dgeId, dgeStatus: 'done', chkId: chkId
            })
            if (res.status !== 200) {
                return this.$message.error(res.msg)
            }
            this.$message.success('审核成功!')
        }

    },
    computed: {
        pagetestlist: function () {
            var search = this.queryInfo.searchInfo
            if (search) {
                return this.AllChkList.filter(function (data) {
                    return Object.keys(data).some(function (key) {
                        return String(data[key]).toLowerCase().indexOf(search) > -1
                    })
                })
            }
            return this.AllChkList.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
        }
    }
}
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .white_btn{
        letter-spacing: 2px;
        color: #009199;
    }
    .white_btn:hover,.white_btn:focus{
        background: #00B6C0;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .icon_add{
        width: 20px;
        height: 20px;
        padding-right: 2px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
    /deep/.el-checkbox__inner:hover {
        border-color: #009199;
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: white;
    }
    /deep/.el-checkbox__inner{
        /*缩放*/
        /*transform: scale(1.2,1.2);*/
    }
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #009199;
        border-color: #009199;
    }
    /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    /deep/.el-form-item__label {
        /*width: 180px;*/
        color: #99a9bf;
    }
    /deep/.el-dropdown{
        cursor: pointer;
    }
</style>

<style lang="less" scoped>
    .el-button+.el-button{
        margin-left: 0px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #009199;
        border-color: #009199;
    }
    .el-checkbox__inner.is-focus .el-checkbox__inner {
        border-color: #009199;
    }
    .el-table{
        margin-top: 44px;
    }
    /*展开行css*/
    .XC_pic{
        width: 90px ;
        height: 90px;
    }
    .img_pad{
        margin: 30px 0;
    }
</style>
