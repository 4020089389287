<template>
    <div>
        <!--    面包屑导航区域-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/AddDge'}">隐患录入</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dgeInfo'}">隐患列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dgeTypeInfo'}"><span class="hight">隐患类型列表</span></el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <!--            功能栏-->
            <el-row type="flex" align="middle">
                <el-col :span="10" :offset="1">
                    <el-input placeholder="请输入搜索内容" v-model="queryInfo.searchInfo" clearable @clear="getDgeType">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col v-if="this.role === 'admin'" :span="6" :offset="7">
                    <el-button class="addcount" @click="addDialogVisible = true">
                        <svg class="icon_add" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <polygon id="路径" fill="#FFFFFF" points="11.34223 8.55450006 11.34223 1.52678484 8.5311359 1.52678484 8.5311359 8.55450006 1.50342068 8.55450006 1.50342068 11.3655941 8.5311359 11.3655941 8.5311359 18.3933093 11.34223 18.3933093 11.34223 11.3655941 18.3699652 11.3655941 18.3699652 8.55450006"></polygon>
                        </svg>
                        创建隐患类型
                    </el-button>
                </el-col>
            </el-row>
            <div>
                <el-scrollbar :native="false">
                    <!--           展示所有隐患类型-->
                    <el-table
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            :data="pageTypelist"
                            tooltip-effect="dark"
                            style="width: 100%">
                        <el-table-column align="center" type="index" label="#"></el-table-column>
                        <el-table-column label="隐患类型" prop="dgeTypeName" align="center">
                            <template slot-scope="scope">
                                <i class="el-icon-collection-tag"></i>
                                {{scope.row.dgeTypeName}}
                            </template>
                        </el-table-column>
                        <el-table-column label="类型信息" prop="dgeTypeInfo" align="center">
                            <template slot-scope="scope">
                                <i class="el-icon-info"></i>
                                {{scope.row.dgeTypeInfo}}
                            </template>
                        </el-table-column>
                        <el-table-column  v-if="this.role === 'admin'"  label="操作" align="center"  fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" @click="EditTypeInfo(scope.row)" style="color: #009199">编辑</el-button>
                                <el-button type="text" @click="removeType(scope.row.dgeTypeId)" style="color: red; padding-left: 4px">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--      分页区域-->
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pagenum"
                            :page-sizes="[10, 15, 20, 30]"
                            :page-size="queryInfo.pagesize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </el-scrollbar>
            </div>
        </el-card>

        <el-dialog
                title="修改隐患类型信息"
                :visible.sync="ToOpenEdit"
                width="47.9%" @close="TypeDialogClosed">
            <!--      内容主体区域-->
            <el-form :model="TypeForm" ref="TypeFormRef"  status-icon label-width="100px">
                <el-form-item label="隐患类型名称: " required prop="dgeTypeName">
                    <el-input v-model="TypeForm.dgeTypeName" placeholder="请输入隐患类型名称" ></el-input>
                </el-form-item>
                <el-form-item label="隐患类型描述" prop="dgeTypeInfo">
                    <el-input type="textarea" :autosize="{ minRows:6}" v-model="TypeForm.dgeTypeInfo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="5">
                        <el-button class="addcount" @click="EditTypeForm()">修 改</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="white_btn" style="margin-left: 40px" @click="resetType()">重 置</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>
        <!--    创建类型的对话框-->
        <el-dialog
                title="创建隐患类型"
                :visible.sync="addDialogVisible"
                width="47.9%" @close="addDialogClosed" class="role-mask">
            <!--      内容主体区-->
            <el-form :model="addForm" status-icon ref="addFormRef" label-width="120px">
                <el-form-item label="隐患名称类型" prop="dgeTypeName" required>
                    <el-input v-model="addForm.dgeTypeName" placeholder="请输入隐患类型名称"></el-input>
                </el-form-item>
                <el-form-item label="隐患类型信息" prop="dgeTypeInfo" required>
                    <el-input type="textarea" :autosize="{ minRows:6}" v-model="addForm.dgeTypeInfo" placeholder="请输入隐患类型信息" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--      底部区域-->
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col :span="4" :offset="5">
                        <el-button class="addcount" @click="addType()">创 建</el-button>
                    </el-col>
                    <el-col :span="4" :offset="5">
                        <el-button class="white_btn" style="margin-left: 40px" @click="resetAdd()">重 置</el-button>
                    </el-col>
                </el-row>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "dgeTypeInfo",
        data(){
            return{
                loading:true,
                queryInfo: {
                    // 模糊搜索
                    searchInfo: '',
                    // 当前页数
                    pagenum: 1,
                    pagesize: 10
                },
                total: 0,
                AllTypeList:[],
                TypeForm:{
                    dgeTypeId: '',
                    dgeTypeName: '',
                    dgeTypeInfo: ''
                },
                ToOpenEdit: false,
                addDialogVisible: false,
                addForm:{
                    dgeTypeInfo: '',
                    dgeTypeName: ''
                },
                role : localStorage.getItem('role')
            }
        },
        created() {
          this.getDgeType()
        },
        methods:{
            async getDgeType(){
                const { data: res } = await this.$http.get('/type/allType')
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.AllTypeList = res.data
                this.total = res.data.length
                if (this.AllTypeList.length === res.data.length){
                    this.loading = false
                }
            },
            // 监听 pagesize 改变的事件
            handleSizeChange (newSize) {
                this.queryInfo.pagesize = newSize
                this.getDgeType()
            },
            // 监听页码值改变的事件
            handleCurrentChange (newPage) {
                // console.log(newPage)
                this.queryInfo.pagenum = newPage
                this.getDgeType()
            },
            EditTypeInfo (info) {
                console.log(info)
                this.TypeForm = info
                this.ToOpenEdit = true
            },
            TypeDialogClosed () {
                this.$refs.TypeFormRef.resetFields()
            },
            resetType (){
                this.$refs.TypeFormRef.resetFields()
            },
            async EditTypeForm () {
                // console.log(this.dgeForm.dgeCycle)
                const { data: res } = await this.$http.put('/type/update', this.TypeForm)
                // console.log(res.data)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success( '修改该隐患类型成功!')
                this.ToOpenEdit = false
                this.getDgeType()
            },
            async removeType (id) {
                // console.log(id)
                const confirmResult = await this.$confirm('此操作将永久删除该隐患类型, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).catch(err => err)
                // 若用户确认删除，则返回值为字符串 confirm
                // 若取消删除，返回 cancel
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除')
                }
                // console.log('确认删除')
                const { data: res } = await this.$http.delete('/type/delete/' + id)
                if (res.status !== 200) {
                    return this.$message.error(res.msg)
                }
                this.$message.success('删除该隐患类型成功!')
                this.getDgeType()
            },
            // 监听添加隐患类型对话框的关闭事件
            addDialogClosed () {
                this.$refs.addFormRef.resetFields()
            },
            resetAdd () {
                this.$refs.addFormRef.resetFields()
            },
            addType () {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) return
                    // 调用接口进行用户添加
                    const { data: res } = await this.$http.post('/type/create/', this.addForm)
                    if (res.status !== 200) {
                        return this.$message.error(res.msg)
                    }
                    this.$message.success('创建隐患类型成功!')
                    // console.log(res)
                    // 隐藏添加用户的对话框
                    this.addDialogVisible = false
                    this.getDgeType()
                })
            },
        },
        computed: {
            pageTypelist: function () {
                var search = this.queryInfo.searchInfo
                if (search) {
                    return this.AllTypeList.filter(function (data) {
                        return Object.keys(data).some(function (key) {
                            return String(data[key]).toLowerCase().indexOf(search) > -1
                        })
                    })
                }
                return this.AllTypeList.slice((this.queryInfo.pagenum - 1) * this.queryInfo.pagesize, this.queryInfo.pagenum * this.queryInfo.pagesize)
            }
        }
    }
</script>

<style scoped>
    .addcount{
        background: #009199;
        color: white;
        letter-spacing: 2px;
    }
    .addcount:hover, .addcount:active, .addcount:focus{
        background: #00a199;
        color: ghostwhite;
        letter-spacing: 2px;
    }
    .icon_add{
        width: 20px;
        height: 20px;
        padding-right: 2px;
        vertical-align:middle;
    }
    .hight{
        color: #009199 !important;
        font-weight: bold;
    }
</style>

<style lang="less" scoped>
.el-button+.el-button{
    margin-left: 0px;
}
.el-table{
    margin-top: 44px;
}
</style>
